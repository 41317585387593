<template>
  <div class="container page__inner">
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="isLoading"
      style="width: 100%"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>

    <UnderwritingAside
      v-if="!isLoading && customerInfo != null"
      v-bind:customer-name="customerInfo.customerFullname"
      v-bind:customer-key="customerInfo.customerKey"
      :customerLoanDeposit="customerLoanDeposits"
      @imageAlert="imageAlert"
    ></UnderwritingAside>

    <main class="page__content2" v-if="!isLoading">
      <div class="page__head d-flex">
        <div
          class="page__head__container ml-auto align-items-center justify-content-between"
        >
          <div class="page__head__actions">
            <div class="d-flex">
              <!-- <button
                type="button"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                v-show="!hideButtons && userCanApprove && (!RequireOfferAcceptance || isCurrentUserFirstApprover)"
              >
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Approve')"
                  >Approve</span
                >
              </button> -->
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-show="!hideButtons && userCanApprove"
              >
                <!-- v-show="!hideButtons && userCanApprove && loanIsTwoLevelApproval && (isCurrentUserSecondApprover && !RequireOfferAcceptance) && isSecondApproval" -->
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Approve')"
                  >Approve</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-show="
                  AllowUnderwriterOveride &&
                  !hideButtons &&
                  isNotLevelOneApproval
                "
              >
                <!-- v-show="(
                  AllowUnderwriterOveride &&
                  !hideButtons &&
                  (!isCurrentUserFirstApprover || !loanIsTwoLevelApproval) 
                )" -->
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Approve override')"
                  >Approve (Override)</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-show="!hideButtons && userCanSendOfferLetter"
              >
                <!-- task.secondApprovalUserKey == null &&  put inside v-show -->
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Pending Acceptance')"
                  >Send Offer</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-show="!hideButtons && userCanReject"
              >
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Reject')"
                  >Reject</span
                >
              </button>
              <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
                v-show="!hideButtons && userCanRework"
              >
                <span
                  class="d-inline-flex mr-2"
                  data-toggle="modal"
                  data-target="#statusModal"
                  @click="preOpenStatusForm('Rework')"
                  >Rework</span
                >
              </button>
              <!-- <div
                class="align-self-center mx-2 dropdown"
                v-show="!hideButtons"
              >
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  more
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#editLoanAmount"
                    @click="clearLoanForm()"
                    >Edit Loan Amount</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-modal="#addLoanAccountFieldModal"
                    v-if="userCanCreateLoanAccount"
                    @click="toggleAddField"
                    >Add Field</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#editInterest"
                    @click="clearLoanForm()"
                    >Edit interest rate</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#editRepayment"
                    @click="clearLoanForm()"
                    >Edit First repayment Date</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#regeneratedScheduleModal"
                    @click="regenerateRepaymentSchedule()"
                    >Regenerate Loan schedule</a
                  >
                </div>
              </div> -->
              <!-- <button
                type="button"
                class="mx-2 button page__body__header__button button--grey text--capital"
              >
                <img src="assets/img/printer.svg" alt="" srcset="" />
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="page__body">
        <div>
          <div class="page__body__header">
            <ul
              class="nav page__tabs page__tabs2"
              id="page-Tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active text--capital"
                  id="pills-details-tab"
                  data-toggle="tab"
                  href="#pills-details"
                  role="tab"
                  aria-controls="pills-details"
                  aria-selected="true"
                  >details</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-schedule-tab"
                  @click="getLoanAccountDetailsSchedule"
                  data-toggle="tab"
                  href="#pills-schedule"
                  role="tab"
                  aria-controls="pills-schedule"
                  aria-selected="false"
                  >schedule</a
                >
              </li>
              <!-- <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-transactions-tab"
                  data-toggle="tab"
                  href="#pills-transactions"
                  role="tab"
                  aria-controls="pills-transactions"
                  aria-selected="false"
                  >transactions</a
                >
              </li> -->
              <li class="nav-item" v-if="!productHasSecurity">
                <a
                  class="nav-link text--capital"
                  id="pills-security-tab"
                  data-toggle="tab"
                  href="#pills-security"
                  role="tab"
                  aria-controls="pills-security"
                  aria-selected="false"
                  >securities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-activities-tab"
                  @click="getLoanAccountDetailsActivities"
                  data-toggle="tab"
                  href="#pills-activities"
                  role="tab"
                  aria-controls="pills-activities"
                  aria-selected="false"
                  >activities</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-attachments-tab"
                  @click="getLoanAccountDetailsAttachments"
                  data-toggle="tab"
                  href="#pills-attachments"
                  role="tab"
                  aria-controls="pills-attachments"
                  aria-selected="false"
                  >attachments</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link text--capital"
                  id="pills-comments-tab"
                  @click="getLoanComments"
                  data-toggle="tab"
                  href="#pills-comments"
                  role="tab"
                  aria-controls="pills-comments"
                  aria-selected="false"
                  >comments</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content" id="page-TabsContent">
            <div
              class="tab-pane fade show active"
              id="pills-details"
              role="tabpanel"
              aria-labelledby="pills-details-tab"
            >
              <div v-if="loanAccountDetails != null">
                <div class="row mt5 mb5">
                  <div
                    class="col-4 m-auto"
                    v-if="task != null && previousApprovalExists"
                  >
                    <div
                      class="details-card"
                      v-bind:class="{
                        'details-card--green':
                          approvalHistory.previousApprovalStatus ===
                            'Approved' ||
                          approvalHistory.previousApprovalStatus === 'Approve',
                        'details-card--red': task.secondRed,
                        'details-card--orange':
                          approvalHistory.previousApprovalStatus ===
                          'AwaitingAction',
                        'details-card--black':
                          approvalHistory.previousApprovalStatus ===
                          'ReworkQueue',
                      }"
                    >
                      <span class="card_title d-block">
                        Previous Approval Review Status</span
                      >
                      <span class="card_title d-block">
                        Previous Approval Name:
                        {{ approvalHistory.previousApprovalLevelName }}
                      </span>
                      <span
                        class="card_subtitle d-block"
                        v-if="
                          approvalHistory.previousApprovalStatus ===
                            'Approved' ||
                          approvalHistory.previousApprovalStatus === 'Approve'
                        "
                      >
                        Approved
                      </span>
                      <span class="card_subtitle d-block" v-else>
                        {{ approvalHistory.previousApprovalStatus }}
                      </span>
                    </div>
                  </div>

                  <div class="col-4 m-auto" v-if="task != null">
                    <div
                      class="details-card"
                      v-bind:class="{
                        'details-card--green':
                          approvalHistory.currentApprovalStatus ===
                            'Approved' ||
                          approvalHistory.currentApprovalStatus === 'Approve',
                        'details-card--red': task.secondRed,
                        'details-card--orange':
                          approvalHistory.currentApprovalStatus ===
                          'AwaitingAction',
                      }"
                    >
                      <span class="card_title d-block">
                        Current Approval Review Status
                      </span>
                      <span class="card_title d-block">
                        Approval Level Name:
                        {{ approvalHistory.currentApprovalLevelName }}
                      </span>
                      <span
                        class="card_subtitle d-block"
                        v-if="
                          approvalHistory.currentApprovalStatus?.toLowerCase() ===
                            'approve' ||
                          approvalHistory.currentApprovalStatus?.toLowerCase() ===
                            'approve'
                        "
                      >
                        Approved
                      </span>
                      <span class="card_subtitle d-block" v-else>
                        {{ approvalHistory.currentApprovalStatus }}
                      </span>
                    </div>
                  </div>

                  <div
                    class="col-4 m-auto"
                    v-if="task != null && nextApprovalExists"
                  >
                    <div
                      class="details-card"
                      v-bind:class="{
                        'details-card--green':
                          approvalHistory.nextApprovalStatus?.toLowerCase() ===
                            'approved' ||
                          approvalHistory.nextApprovalStatus?.toLowerCase() ===
                            'approve',
                        'details-card--red': task.secondRed,
                        'details-card--orange':
                          approvalHistory.nextApprovalStatus?.toLowerCase() ===
                          'awaitingaction',
                      }"
                    >
                      <span class="card_title d-block">
                        Next Approval Review Status
                      </span>
                      <span class="card_title d-block">
                        Next Approval Name:
                        {{ approvalHistory.nextApprovalLevelName }}
                      </span>
                      <span
                        class="card_subtitle d-block"
                        v-if="
                          approvalHistory.nextApprovalStatus?.toLowerCase() ===
                            'approve' ||
                          approvalHistory.nextApprovalStatus?.toLowerCase() ===
                            'approved'
                        "
                      >
                        Approved
                      </span>
                      <span class="card_subtitle d-block" v-else>
                        {{ approvalHistory.nextApprovalStatus }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="col-4 m-auto">
                    <div class="details-card details-card--orange">
                      <span class="card_title d-block"> Account State </span>
                      <span class="card_subtitle d-block">
                        {{
                          loanAccountDetails.accountState | formatEnumToProper
                        }}
                      </span>
                    </div>
                  </div> -->
                </div>
                <hr />
                <div>
                  <div class="row mt5">
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          general
                        </p>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Account ID
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                            :title="
                              loanAccountDetails
                                ? loanAccountDetails.uniqueLoanId
                                  ? loanAccountDetails.uniqueLoanId
                                  : 'NA'
                                : 'NA'
                            "
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.uniqueLoanId
                                  ? loanAccountDetails.uniqueLoanId
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Assigned to Branch
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.assignedBranchName
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Account State
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.accountState
                                  ? loanAccountDetails.accountState.replace(
                                      /_/g,
                                      " "
                                    )
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Original Account
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle text--primary"
                          >
                            {{ loanAccountDetails.originalAccount }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Currency
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            NGN
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Activation Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ loanAccountDetails.approvedDate | moment }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Product
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle text--primary"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.adminLoanProduct
                                  ? loanAccountDetails.adminLoanProduct
                                      .product_name
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Product Type
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            Fixed Term Loan
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Loan Amount
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.loanAmount
                                  ? toNaira(loanAccountDetails.loanAmount)
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Rate
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.interestRate !== null
                                  ? loanAccountDetails.interestRate
                                  : ""
                                : ""
                            }}
                            {{ this.interestRateDisplay }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Calculation Method
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.adminLoanProduct !== null
                                  ? loanAccountDetails.adminLoanProduct
                                      .interestCalculationMethod !== null
                                    ? interestCalculationMethod(
                                        loanAccountDetails.adminLoanProduct
                                          .interest_calculation_method
                                      )
                                    : ""
                                  : ""
                                : ""
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Accrued Interest Posting Frequency
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.adminLoanProduct
                                  ? accruedInterestPostingFrequency(
                                      loanAccountDetails.adminLoanProduct
                                        .interest_application_method
                                    )
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Arrears Tolerance Period
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.arrearsTolerancePeriod !==
                                    null ||
                                  loanAccountDetails.arrearsTolerancePeriod !==
                                    undefined
                                  ? loanAccountDetails.arrearsTolerancePeriod +
                                    " days"
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            With a floor (minimum)
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.arrearsTolerancePeriod !==
                                    null ||
                                  loanAccountDetails.arrearsTolerancePeriod !==
                                    udefined
                                  ? loanAccountDetails.arrearsTolerancePeriod +
                                    " days"
                                  : "NA"
                                : "NA"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Repayment allocation method
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            Horizontal
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Repayments are made every
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.repaymentPeriodCount
                                  ? loanAccountDetails.repaymentPeriodCount
                                  : ""
                                : ""
                            }}
                            <!-- {{ loanInterestType }} -->
                            {{
                              repaymentAreMadeEvery(
                                loanAccountDetails.adminLoanProduct
                                  .repayment_schedule_method
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Number of Installments
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.repaymentInstallments
                                  ? loanAccountDetails.repaymentInstallments
                                  : "NA"
                                : "NA"
                            }}
                            Installments
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Collect principal every
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails.defaultPrincipalRepaymentInterval
                            }}
                            {{
                              loanAccountDetails.defaultPrincipalRepaymentInterval >
                              1
                                ? "Repayments"
                                : "Repayment"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            pre-payment Acceptance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.adminLoanProduct
                                  ? loanAccountDetails.adminLoanProduct
                                      .future_payments_acceptance === 0
                                    ? "Yes"
                                    : "No"
                                  : "No"
                                : "No"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Accept Pre-payment of Future Interest
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.adminLoanProduct
                                  ? loanAccountDetails.adminLoanProduct
                                      .future_payments_acceptance === 0
                                    ? "Accept interest pre-payments"
                                    : "Accept Postdated Payments"
                                  : "No"
                                : "No"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Requires Offer Acceptance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ RequireOfferAcceptance ? "Yes" : "No" }}
                          </span>
                        </div>

                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Allow Override
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ AllowUnderwriterOveride }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Acceptance Status
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ loanAccountDetails.acceptanceStatus }}
                          </span>
                        </div>

                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Over ride Action
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              isOVerRide
                                ? `${this.loanAccountDetails.overrideStatus} by ${loanAccountDetails.underWriterName}`
                                : this.loanAccountDetails.overrideStatus
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          Details
                        </p>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances
                                        .principalBalance
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances
                                        .interestBalance
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Incurred
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances
                                        .interestBalance
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Fee Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.feesBalance
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.penaltyBalance
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Total Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ toNaira(loanAccountDetails.totalDue) }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.principalDue
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.interestDue
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Fee Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(loanAccountDetails.balances.feesDue)
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.penaltyDue
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Total Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      totalPaid(loanAccountDetails.balances)
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.principalPaid
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.interestPaid
                                    )
                                  : "_"
                                : "_"
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Fees Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.feesPaid
                                    )
                                  : ""
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters border-0 account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails
                                ? loanAccountDetails.balances
                                  ? toNaira(
                                      loanAccountDetails.balances.penaltyPaid
                                    )
                                  : ""
                                : "_"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 mt-5">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          Disbursement Details
                        </p>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Anticipated Disburse Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.anticipatedDisbursementDetails
                                .anticipatedDisbursementDate | moment
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Disbursed Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.disbursementDetails
                                .disbursementDate | moment
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Anticipated Disbursement Account
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.anticipatedDisbursementDetails
                                .anticipatedBankAccountNumber
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Disbursed Account Number
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.disbursementDetails
                                .disbursedAccountNumber
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Anticipated Disbursement Bank
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.anticipatedDisbursementDetails
                                .anticipatedBankName
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Disbursed to Bank
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.disbursementDetails
                                .disbursedBankName
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Anticipated Recipient Account Name
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.anticipatedDisbursementDetails
                                .anticipatedBankAccountName
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Disbursed Account Name
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.disbursementDetails
                                .disbursedAccountName
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            First Repayment Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.anticipatedDisbursementDetails
                                .firstRepaymentDate | moment
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Disbursed Amount
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle overlap"
                          >
                            {{
                              loanAccountDetails.disbursementDetails
                                .disbursedAmount | currency
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <SecondLevelDetails
                    :data="uiProps.accountCustomFieldSets"
                    :userCanViewLoanAccount="userCanViewLoanAccount"
                    :userCanEditLoanAccount="userCanEditLoanAccount"
                    @editField="editCustomField"
                  />
                  <!-- <div class="row mt5">
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          general
                        </p>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Account id
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                            v-if="customerInfo != null"
                          >
                            {{ customerInfo.uniqueCustomerId }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Assigned to Branch
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                            v-if="customerInfo != null"
                          >
                            {{ customerInfo.branch.branchName }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Account State
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                            v-if="customerInfo != null"
                          >
                            {{ customerInfo.customerStatus }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Activation Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Product
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle text--primary"
                            v-if="loanProduct != null"
                          >
                            {{ loanProduct.productName }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Product Type
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                            v-if="loanProduct != null"
                          >
                            {{
                              loanProduct.loanProductType | formatEnumToProper
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Loan Amount
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦
                            {{
                              loanAccountDetails.loanAmount | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Rate
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails.interestSettings.interestRate
                                | formatCurrencyPipe
                            }}
                            %
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Calculation Method
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails.interestSettings
                                .interestCalculationMethod
                            }}
                          </span>
                        </div>
                      
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Arrears Tolerance Period
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{ loanAccountDetails.arrearsTolerancePeriod }} days
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            pre-payment Acceptance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails.adminLoanProduct
                                .prepayment_acceptance
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Accept Pre-payment of Future Interest
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            {{
                              loanAccountDetails.adminLoanProduct
                                .future_payments_acceptance
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          Details
                        </p>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦
                            {{
                              loanAccountDetails.balances.principalBalance
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.interestBalance
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Incured
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            null{₦53,246.72}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Free Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            null{₦0.00}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.penaltyBalance
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Total Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            null{₦0.00}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.principalDue
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.interestDue
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Fee Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.feesDue
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Due
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.penaltyDue
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Total Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            null{₦0.00}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Principal Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.principalPaid
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Interest Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.interestPaid
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Fees Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.feesPaid
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters border-0 account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Penalty Paid
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            ₦{{
                              loanAccountDetails.balances.penaltyPaid
                                | formatCurrencyPipe
                            }}
                          </span>
                        </div>
                      </div>

                      <div class="row mt5">
                        <div class="col-12">
                          <div class="account_item_head">
                            <p class="account_item_head_text text--white">
                              Settlement Account
                            </p>
                          </div>
                          <div
                            class="row no-gutters account_item align-items-center"
                          >
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Settlement Account
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                null{1100891242}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="row mt5">
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          Risk Assesment
                        </p>
                        <div class="account_item_head_action">
                          <a
                            class="text--primary text--capital account_item_head_text mr-3"
                            data-modal="#editRiskFieldModal"
                            >edit</a
                          >
                          <a
                            class="text--success text--capital account_item_head_text"
                            data-modal="#addRiskFieldModal"
                            >Add field</a
                          >
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Loan Liquidation Amount
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Bank Name(Repayment Account)
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Bank Account Number
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            5483937893
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Late Payment Fee
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Last Penalty Application Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Last Payment Application Date
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                      </div>
                      <div
                        class="row border-0 no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Requires Gurantor
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            NO
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Write off balance
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            -
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="account_item_head">
                        <p class="account_item_head_text text--white">
                          Account Officer Details
                        </p>
                        <div class="account_item_head_action">
                          <a
                            class="text--primary text--capital account_item_head_text mr-3"
                            >edit</a
                          >
                          <a
                            class="text--success text--capital account_item_head_text"
                            data-modal="#addAccOfficerFieldModal"
                            >Add field</a
                          >
                        </div>
                      </div>
                      <div
                        class="row no-gutters account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Loan Officer
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            Moneypal Web
                          </span>
                        </div>
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Loan Officer Branch
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            Head office
                          </span>
                        </div>
                      </div>
                      <div
                        class="row no-gutters border-0 account_item align-items-center"
                      >
                        <div class="col-6">
                          <span
                            class="text--black text--opaq2 text--small text--bold account_item_title"
                          >
                            Acquisation Channel
                          </span>
                          <span
                            class="text--black text--regular account_item_subtitle"
                          >
                            Agency Sales Portal
                          </span>
                        </div>
                      </div>

                      <div class="row mt5">
                        <div class="col-12">
                          <div class="account_item_head">
                            <p class="account_item_head_text text--white">
                              Disbursement Requirement
                            </p>
                            <div class="account_item_head_action">
                              <a
                                class="text--primary text--capital account_item_head_text mr-3"
                                >edit</a
                              >
                              <a
                                class="text--success text--capital account_item_head_text"
                                data-modal="#addDisburseFieldModal"
                                >Add field</a
                              >
                            </div>
                          </div>
                          <div
                            class="row no-gutters account_item align-items-center"
                          >
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Repayment Method
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                Direct Debit
                              </span>
                            </div>
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Repayment instrument status
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                -
                              </span>
                            </div>
                          </div>
                          <div
                            class="row no-gutters account_item align-items-center"
                          >
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Disbursement Account
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                FIRST Bank
                              </span>
                            </div>
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Disbursement Account Number
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                300089897493
                              </span>
                            </div>
                          </div>
                          <div
                            class="row no-gutters account_item align-items-center"
                          >
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Auto Disburse
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                Yes
                              </span>
                            </div>
                            <div class="col-6">
                              <span
                                class="text--black text--opaq2 text--small text--bold account_item_title"
                              >
                                Disbursement Account Validation status
                              </span>
                              <span
                                class="text--black text--regular account_item_subtitle"
                              >
                                -
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-schedule"
              role="tabpanel"
              aria-labelledby="pills-schedule-tab"
            >
              <div class="d-flex justify-content-between py-5">
                <!-- <label
                  for="paidrepayment"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Paid Repayment
                  <input type="checkbox" id="paidrepayment" class="form-" />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="emptyrepayment"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Empty Repayment
                  <input type="checkbox" id="emptyrepayment" class="form-" />
                  <span class="checkmark"></span>
                </label> -->
                <label
                  for="schedule"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Schedule
                  <input type="checkbox" id="schedule" class="form-" />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="amtexpected"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Amount Expected
                  <input
                    type="checkbox"
                    id="amtexpected"
                    class="form-"
                    v-model="showAmountExpected"
                  />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="amtpaid"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Amount Paid
                  <input
                    type="checkbox"
                    id="amtpaid"
                    class="form-"
                    v-model="showAmountPaid"
                  />
                  <span class="checkmark"></span>
                </label>
                <label
                  for="amtdue"
                  class="d-block has-checkbox text--black text--500 text--regular"
                  >Amount Due
                  <input
                    type="checkbox"
                    id="amtdue"
                    class="form-"
                    v-model="showAmountDue"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <hr />
              <div class="mt5 w-100" style="overflow-x: scroll">
                <!-- <h6 class="text--regular-alt text--capital text--bold">
                  Schedule
                </h6> -->
                <table class="customtable _customtable">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--left"
                      >
                        Due
                      </th>

                      <!--EXPECTED SECTION-->
                      <template v-if="showAmountExpected === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__left"
                        >
                          Principal Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Interest Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Fees Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Penalty Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__right"
                        >
                          Total Expected
                        </th>
                      </template>

                      <!--EXPECTED SECTION-->
                      <template v-if="showAmountPaid === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__left"
                        >
                          Principal Paid
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Interest Paid
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Fees Paid
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Penalty Paid
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__right"
                        >
                          Paid Date
                        </th>
                      </template>

                      <!--EXPECTED SECTION-->
                      <template v-if="showAmountDue === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__left"
                        >
                          Principal Due
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Interest Due
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Fees Due
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small"
                        >
                          Penalty Due
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small table__amount__right"
                        >
                          Total Due
                        </th>
                      </template>

                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small table__amount__left"
                      >
                        State
                      </th>
                      <!-- <template>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital"
                        >
                          Due
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital"
                        >
                          State
                        </th>
                      </template>
                      <template v-if="showAmountExpected === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Principal Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Interest Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Fees Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Penalty Expected
                        </th>
                      </template>
                      <template v-if="showAmountPaid === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Principal Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Interest Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Fees Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Penalty Expected
                        </th>
                      </template>
                      <template v-if="showAmountDue === true">
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Principal Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Interest Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Fees Expected
                        </th>
                        <th
                          scope="col"
                          class="table__header__text table__text text--capital text--small text-600"
                        >
                          Penalty Expected
                        </th>
                      </template> -->
                    </tr>
                  </thead>
                  <tbody v-show="schedule">
                    <tr v-for="(item, i) in schedule" :key="i">
                      <template>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital p-2"
                            >{{ item.installment }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ item.due | moment("DD/MM/YYYY") }}</span
                          >
                        </td>
                        <!--EXPECTED SECTION-->
                        <template v-if="showAmountExpected == true">
                          <td class="table__amount__left">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.expectedPrincipal) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.interestExpected) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.feeExpected) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.penaltyExpected) }}</span
                            >
                          </td>
                          <td class="table__amount__right">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.totalExpected) }}</span
                            >
                          </td>
                        </template>

                        <!--PAID SECTION-->
                        <template v-if="showAmountPaid == true">
                          <td class="table__amount__left">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.principalPaid) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.interestPaid) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ item.feesPaid }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.penaltyPaid) }}</span
                            >
                          </td>
                          <td class="table__amount__right">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ item.paidDate | moment("DD/MM/YYYY") }}</span
                            >
                          </td>
                        </template>

                        <!--PAID SECTION-->
                        <template v-if="showAmountDue == true">
                          <td class="table__amount__left">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.principalDue) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.interestDue) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.feesDue) }}</span
                            >
                          </td>
                          <td class="">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.penaltyDue) }}</span
                            >
                          </td>
                          <td class="table__amount__right">
                            <span
                              class="table__body__text table__text text--capital"
                              >{{ toNaira(item.totalDue) }}</span
                            >
                          </td>
                        </template>

                        <td class="table__amount__left">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ item.state }}</span
                          >
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td class="">
                        <span
                          class="table__body__text table__text text--capital"
                          >N</span
                        >
                      </td>
                      <td class="">
                        <span
                          class="table__body__text table__text text--capital"
                          >Total</span
                        >
                      </td>
                      <template v-if="showAmountExpected">
                        <td class="table__amount__left">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalExpectedAmt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalExpectedInt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalExpectedFee(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalExpectedPen(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalExpectedSum(schedule)) }}</span
                          >
                        </td>
                      </template>
                      <template v-if="showAmountPaid">
                        <td class="table__amount__left">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalPaidAmt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalPaidInt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalPaidFee(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalPaidPen(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                          ></span>
                        </td>
                        <!-- <td class="">
                          <span class="table__body__text table__text text--capital">{{
                            toNaira(totalPaidSum(schedule))
                          }}</span>
                        </td> -->
                      </template>
                      <template v-if="showAmountDue">
                        <td class="table__amount__left">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalDueAmt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalDueInt(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalDueFee(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalDuePen(schedule)) }}</span
                          >
                        </td>
                        <td class="">
                          <span
                            class="table__body__text table__text text--capital"
                            >{{ toNaira(totalDueSum(schedule)) }}</span
                          >
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="pagination">
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <div class="text--capital text--regular pagination__text">
                      showing
                      <span
                        class="d-inline-block text--primary"
                        v-if="schedulePaging.totalCount == 0"
                        >0</span
                      >
                      <span class="d-inline-block text--primary" v-else>{{
                        !schedulePaging.hasPreviousPage
                          ? schedulePaging.pageIndex
                          : schedulePaging.pageSize *
                              (schedulePaging.pageIndex - 1) +
                            1
                      }}</span>
                      to
                      <span class="d-inline-block text--primary">{{
                        schedulePaging.hasNextPage
                          ? schedulePaging.pageSize * schedulePaging.pageIndex
                          : schedulePaging.totalCount
                      }}</span>
                      of
                      <span>{{ schedulePaging.totalCount }}</span>
                    </div>
                    <div
                      class="pagination__buttons d-flex align-items-center justify-content-between"
                    >
                      <button
                        class="button button--sm text--regular"
                        v-if="schedulePaging.hasPreviousPage"
                        @click="stepPage('schedule', 'previous')"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="button text--regular button--sm ml-2"
                        v-for="num in schedulePaging.totalPages"
                        :key="num"
                        :class="{
                          'button--primary text--white':
                            schedulePaging.pageIndex === num
                        }"
                        :disabled="schedulePaging.pageIndex === num"
                        @click="changePage('schedule', num)"
                      >
                        {{ num }}
                      </button>

                      <button
                        class="button text--regular button--sm ml-2"
                        v-if="schedulePaging.hasNextPage"
                        @click="stepPage('schedule', 'next')"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div> -->
              </div>

              <!-- <div
                class="mt5"
                v-if="amountExpected != null && showAmountExpected"
              >
                <h6 class="text--regular-alt text--capital text--bold">
                  Amount Expected
                </h6>
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Due
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Principal Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Interest Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Fees Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Penalty Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in amountExpected"
                      :key="'amountExpected' + index"
                    >
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.dueDate | formatDatePipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.principalExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.interestExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{ item.feesExpected | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.penaltyExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.state }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination">
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <div class="text--capital text--regular pagination__text">
                      showing
                      <span
                        class="d-inline-block text--primary"
                        v-if="amountExpectedPaging.totalCount == 0"
                        >0</span
                      >
                      <span class="d-inline-block text--primary" v-else>{{
                        !amountExpectedPaging.hasPreviousPage
                          ? amountExpectedPaging.pageIndex
                          : amountExpectedPaging.pageSize *
                              (amountExpectedPaging.pageIndex - 1) +
                            1
                      }}</span>
                      to
                      <span class="d-inline-block text--primary">{{
                        amountExpectedPaging.hasNextPage
                          ? amountExpectedPaging.pageSize *
                            amountExpectedPaging.pageIndex
                          : amountExpectedPaging.totalCount
                      }}</span>
                      of
                      <span>{{ amountExpectedPaging.totalCount }}</span>
                    </div>
                    <div
                      class="pagination__buttons d-flex align-items-center justify-content-between"
                    >
                      <button
                        class="button button--sm text--regular"
                        v-if="amountExpectedPaging.hasPreviousPage"
                        @click="stepPage('amountExpected', 'previous')"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="button text--regular button--sm ml-2"
                        v-for="num in amountExpectedPaging.totalPages"
                        :key="num"
                        :class="{
                          'button--primary text--white':
                            amountExpectedPaging.pageIndex === num
                        }"
                        :disabled="amountExpectedPaging.pageIndex === num"
                        @click="changePage('amountExpected', num)"
                      >
                        {{ num }}
                      </button>

                      <button
                        class="button text--regular button--sm ml-2"
                        v-if="amountExpectedPaging.hasNextPage"
                        @click="stepPage('amountExpected', 'next')"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="mt5" v-if="amountPaid != null && showAmountPaid">
                <h6 class="text--regular-alt text--capital text--bold">
                  Amount Paid
                </h6>
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Due
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Principal Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Interest Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Fees Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Penalty Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in amountPaid"
                      :key="'amountPaid' + index"
                    >
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.dueDate | formatDatePipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.principalExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.interestExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{ item.feesExpected | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.penaltyExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.state }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination">
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <div class="text--capital text--regular pagination__text">
                      showing
                      <span
                        class="d-inline-block text--primary"
                        v-if="amountPaidPaging.totalCount == 0"
                        >0</span
                      >
                      <span class="d-inline-block text--primary" v-else>{{
                        !amountPaidPaging.hasPreviousPage
                          ? amountPaidPaging.pageIndex
                          : amountPaidPaging.pageSize *
                              (amountPaidPaging.pageIndex - 1) +
                            1
                      }}</span>
                      to
                      <span class="d-inline-block text--primary">{{
                        amountPaidPaging.hasNextPage
                          ? amountPaidPaging.pageSize *
                            amountPaidPaging.pageIndex
                          : amountPaidPaging.totalCount
                      }}</span>
                      of
                      <span>{{ amountPaidPaging.totalCount }}</span>
                    </div>
                    <div
                      class="pagination__buttons d-flex align-items-center justify-content-between"
                    >
                      <button
                        class="button button--sm text--regular"
                        v-if="amountPaidPaging.hasPreviousPage"
                        @click="stepPage('amountPaid', 'previous')"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="button text--regular button--sm ml-2"
                        v-for="num in amountPaidPaging.totalPages"
                        :key="num"
                        :class="{
                          'button--primary text--white':
                            amountPaidPaging.pageIndex === num
                        }"
                        :disabled="amountPaidPaging.pageIndex === num"
                        @click="changePage('amountPaid', num)"
                      >
                        {{ num }}
                      </button>

                      <button
                        class="button text--regular button--sm ml-2"
                        v-if="amountPaidPaging.hasNextPage"
                        @click="stepPage('amountPaid', 'next')"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="mt5" v-if="amountDue != null && showAmountDue">
                <h6 class="text--regular-alt text--capital text--bold">
                  Amount Due
                </h6>
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Due
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Principal Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Interest Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Fees Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        Penalty Expected
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--small text-600"
                      >
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in amountDue"
                      :key="'amountDue' + index"
                    >
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.dueDate | formatDatePipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.principalExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.interestExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{ item.feesExpected | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >₦{{
                            item.penaltyExpected | formatCurrencyPipe
                          }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--regular text--black"
                          >{{ item.state }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination">
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <div class="text--capital text--regular pagination__text">
                      showing
                      <span
                        class="d-inline-block text--primary"
                        v-if="amountDuePaging.totalCount == 0"
                        >0</span
                      >
                      <span class="d-inline-block text--primary" v-else>{{
                        !amountDuePaging.hasPreviousPage
                          ? amountDuePaging.pageIndex
                          : amountDuePaging.pageSize *
                              (amountDuePaging.pageIndex - 1) +
                            1
                      }}</span>
                      to
                      <span class="d-inline-block text--primary">{{
                        amountDuePaging.hasNextPage
                          ? amountDuePaging.pageSize * amountDuePaging.pageIndex
                          : amountDuePaging.totalCount
                      }}</span>
                      of
                      <span>{{ amountDuePaging.totalCount }}</span>
                    </div>
                    <div
                      class="pagination__buttons d-flex align-items-center justify-content-between"
                    >
                      <button
                        class="button button--sm text--regular"
                        v-if="amountDuePaging.hasPreviousPage"
                        @click="stepPage('amountDue', 'previous')"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="button text--regular button--sm ml-2"
                        v-for="num in amountDuePaging.totalPages"
                        :key="num"
                        :class="{
                          'button--primary text--white':
                            amountDuePaging.pageIndex === num
                        }"
                        :disabled="amountDuePaging.pageIndex === num"
                        @click="changePage('amountDue', num)"
                      >
                        {{ num }}
                      </button>

                      <button
                        class="button text--regular button--sm ml-2"
                        v-if="amountDuePaging.hasNextPage"
                        @click="stepPage('amountDue', 'next')"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div
              class="tab-pane fade"
              id="pills-transactions"
              role="tabpanel"
              aria-labelledby="pills-transactions-tab"
            >
              <table
                v-if="loanAccountDetailsTransactions != null"
                class="customtable w-100"
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 20%"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 20%"
                    >
                      Value Date (Entry Date)
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      class="table__header__text table__text text--capital"
                      style="width: 15%"
                    >
                      Total Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="transaction in loanAccountDetailsTransactions"
                    :key="transaction.transactionId"
                  >
                    <td>
                      <!-- <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      > -->
                      {{ transaction.accountName }}
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ transaction.transactionId }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ transaction.valueDate | formatDatePipe }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="table__body__text table__text text--capital"
                        >{{ transaction.type }}</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦{{ transaction.amount | formatCurrencyPipe }}</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦{{
                          transaction.totalBalance | formatCurrencyPipe
                        }}</span
                      >
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >926010</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >08-11-2020 00:00:00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Deposit</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >926010</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >08-11-2020 00:00:00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Deposit</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >926010</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >08-11-2020 00:00:00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Deposit</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >926010</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >08-11-2020 00:00:00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Deposit</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="customer-profile.html"
                        class="table__body__text table__text text--capital text--primary"
                        >Babatunde Olofin</a
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >926010</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >08-11-2020 00:00:00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Deposit</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >₦25,000.00</span
                      >
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <!-- <div class="pagination">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div class="text--capital text--regular pagination__text">
                    showing
                    <span class="d-inline-block text--primary">1</span> to
                    <span class="d-inline-block text--primary">10</span> of
                    <span>1200</span>
                  </div>
                  <div
                    class="pagination__buttons d-flex align-items-center justify-content-between"
                  >
                    <button class="button button--sm text--regular">
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button
                      class="button text--regular button--primary text--white text-white button--sm ml-2"
                    >
                      1
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      2
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      3
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      4
                    </button>
                    <button class="button text--regular button--sm ml-2">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <div
              class="tab-pane fade"
              id="pills-security"
              role="tabpanel"
              aria-labelledby="pills-security-tab"
            >
              <div class="row mt5 mb5" v-if="loanAccountDetails != null">
                <div class="col-4 ml-auto">
                  <div class="details-card details-card--orange">
                    <span class="card_title d-block"> Amount Guaranteed </span>
                    <span class="card_subtitle d-block">
                      {{ loanAccountDetails.totalSecurityAmount }}
                    </span>
                  </div>
                </div>
                <div class="col-4 mr-auto">
                  <div class="details-card details-card--green">
                    <span class="card_title d-block">
                      Outstanding Principal Guaranteed
                    </span>
                    <span class="card_subtitle d-block">
                      {{ loanAccountDetails.percentagePrincipalGuaranteed }}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-activities"
              role="tabpanel"
              aria-labelledby="pills-activities-tab"
            >
              <div class="pt-3" v-if="loanAccountDetailsActivities != null">
                <div
                  class="form__item"
                  v-for="activity in loanAccountDetailsActivities"
                  :key="activity.event + activity.name + activity.time"
                >
                  <div class="row align-center">
                    <div class="col-1">
                      <img src="@/assets/img/login.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >{{ activity.name }}</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >{{ activity.event }}</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        {{ activity.time | fromNow }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/login.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/edit-icon.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >edited profile of</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Jerryson</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Changed user state from</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--success text--opaq0 ext--capital text-300"
                          >‘Active’</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >to</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--danger text--capital text-300"
                          >Inactive</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/edit-icon.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >edited profile of</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Jerryson</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Changed user state from</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--success text--opaq0 ext--capital text-300"
                          >‘Active’</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >to</span
                        >&nbsp;<span
                          class="d-inline-block text--regular text--danger text--capital text-300"
                          >Inactive</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/login.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/login.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/login.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >
                        &nbsp;<span
                          class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                          >Logged In</span
                        >
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="row">
                    <div class="col-1">
                      <img src="assets/img/comment.svg" alt="" srcset="" />
                    </div>
                    <div class="col-11">
                      <p>
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          >Babatunde Olofin</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >wrote a comment on</span
                        >&nbsp;
                        <span
                          class="d-inline-block text--black text--regular-alt text--600"
                          > payroll</span
                        >
                      </p>
                      <p>
                        <span
                          class="d-inline-block text--regular text--dark text--opaq1 text-300"
                          >Thanks for the feedback
                        </span>
                      </p>
                      <span class="text--tiny text--dark text-300 text--opaq1">
                        20 mins ago
                      </span>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-attachments"
              role="tabpanel"
              aria-labelledby="pills-attachments-tab"
            >
              <div v-if="documents != null">
                <div class="mb-3" v-if="userCanUploadAttachment">
                  <button
                    class="button button--black d-flex align-items-center ml-auto"
                    data-toggle="modal"
                    data-target="#addDocumentModal"
                  >
                    <span
                      class="text--capital d-inline-flex mr-2 text-white text--small"
                      >upload new attachment</span
                    >
                    <img src="assets/img/upload.svg" alt="" srcset="" />
                  </button>
                </div>
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 20%"
                      >
                        Document
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 15%"
                      >
                        File Type
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 15%"
                      >
                        Size
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 15%"
                      >
                        Creation Date
                      </th>
                      <!-- <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 20%"
                      >
                        Added By
                      </th> -->
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                        style="width: 15%"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="loanAccountDetailsAttachments">
                    <tr
                      v-for="(doc, index) in loanAccountDetailsAttachments.data"
                      :key="index"
                    >
                      <td>
                        <span
                          class="table__body__text table__text text--capital d-inline-flex align-items-center"
                        >
                          <img
                            src="assets/img/paper.svg"
                            alt=""
                            srcset=""
                            class="mr-2"
                          />
                          {{ doc.document_title }}
                        </span>
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ doc.document_type }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ doc.document_file_size | kb }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ doc.creation_date | moment }}</span
                        >
                      </td>
                      <!-- <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >Babatunde olofin</span
                        >
                      </td> -->
                      <td>
                        <div class="align-self-center mx-2 dropdown">
                          <button
                            class="button button--grey text--capital text--small d-flex align-items-center"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            action
                            <span class="dropdown__icon">
                              <ion-icon name="caret-down-outline"></ion-icon>
                            </span>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item text--capital"
                              data-modal="#previewModal"
                              @click="previewFile(doc.document_key)"
                              >Preview</a
                            >
                            <a
                              class="dropdown-item text--capital"
                              @click="downloadFile(doc.document_key)"
                              >Download</a
                            >
                            <!-- <a
                              class="dropdown-item text--capital"
                              data-toggle="modal"
                              data-target="#editDocumentModal"
                              v-if="userCanEditAttachment"
                              @click="editDocument(doc.document_key)"
                              >Edit
                            </a> -->
                            <a
                              class="dropdown-item text--capital"
                              data-modal="#deleteDocumentModal"
                              v-if="userCanDeleteAttachment"
                              @click="
                                openDeleteAttachmentModal(doc.document_key)
                              "
                              >Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                    <td>
                      <span
                        class="table__body__text table__text text--capital d-inline-flex align-items-center"
                      >
                        <img
                          src="assets/img/paper.svg"
                          alt=""
                          srcset=""
                          class="mr-2"
                        />
                        Document 1
                      </span>
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >PDF</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >534.1kb</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >06-12-2020</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Babatunde olofin</span
                      >
                    </td>
                    <td>
                      <div class="align-self-center mx-2 dropdown">
                        <button
                          class="button button--grey text--capital text--small d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          action
                          <span class="dropdown__icon">
                            <ion-icon name="caret-down-outline"></ion-icon>
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item text--capital"
                            data-modal="#previewModal"
                            >Preview</a
                          >
                          <a class="dropdown-item text--capital">Download</a>
                          <a class="dropdown-item text--capital">Edit</a>
                          <a
                            class="dropdown-item text--capital"
                            data-modal="#deleteDocumentModal"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="table__body__text table__text text--capital d-inline-flex align-items-center"
                      >
                        <img
                          src="assets/img/paper.svg"
                          alt=""
                          srcset=""
                          class="mr-2"
                        />
                        Document 1
                      </span>
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >PDF</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >534.1kb</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >06-12-2020</span
                      >
                    </td>
                    <td>
                      <span class="table__body__text table__text text--capital"
                        >Babatunde olofin</span
                      >
                    </td>
                    <td>
                      <div class="align-self-center mx-2 dropdown">
                        <button
                          class="button button--grey text--capital text--small d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          action
                          <span class="dropdown__icon">
                            <ion-icon name="caret-down-outline"></ion-icon>
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            class="dropdown-item text--capital"
                            data-modal="#previewModal"
                            >Preview</a
                          >
                          <a class="dropdown-item text--capital">Download</a>
                          <a class="dropdown-item text--capital">Edit</a>
                          <a
                            class="dropdown-item text--capital"
                            data-modal="#deleteDocumentModal"
                            >Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr> -->
                  </tbody>
                </table>
                <div class="pagination" v-if="loanAccountDetailsAttachments">
                  <div
                    class="d-flex align-items-center justify-content-between w-100"
                  >
                    <div class="text--capital text--regular pagination__text">
                      showing
                      <span
                        class="d-inline-block text--primary"
                        v-if="loanAccountDetailsAttachments.totalCount == 0"
                        >0</span
                      >
                      <span class="d-inline-block text--primary" v-else>{{
                        !loanAccountDetailsAttachments.hasPrevious
                          ? loanAccountDetailsAttachments.currentPage
                          : loanAccountDetailsAttachments.pageSize *
                              (loanAccountDetailsAttachments.currentPage - 1) +
                            1
                      }}</span>
                      to
                      <span class="d-inline-block text--primary">{{
                        loanAccountDetailsAttachments.hasNext
                          ? loanAccountDetailsAttachments.pageSize *
                            loanAccountDetailsAttachments.currentPage
                          : loanAccountDetailsAttachments.totalCount
                      }}</span>
                      of
                      <span>{{
                        loanAccountDetailsAttachments.totalCount
                      }}</span>
                    </div>
                    <div
                      class="pagination__buttons d-flex align-items-center justify-content-between"
                    >
                      <button
                        class="button button--sm text--regular"
                        v-if="loanAccountDetailsAttachments.hasPrevious"
                        @click="stepPage('document', 'previous')"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="button text--regular button--sm ml-2"
                        v-for="num in loanAccountDetailsAttachments.totalPages"
                        :key="num"
                        :class="{
                          'button--primary text--white':
                            loanAccountDetailsAttachments.currentPage === num,
                        }"
                        :disabled="
                          loanAccountDetailsAttachments.currentPage === num
                        "
                        @click="changePage('document', num)"
                      >
                        {{ num }}
                      </button>

                      <button
                        class="button text--regular button--sm ml-2"
                        v-if="loanAccountDetailsAttachments.hasNext"
                        @click="stepPage('document', 'next')"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-comments"
              role="tabpanel"
              aria-labelledby="pills-comments-tab"
            >
              <div v-if="comments != null">
                <div class="mb-3">
                  <button
                    class="button button--black d-flex align-items-center ml-auto"
                    data-toggle="modal"
                    data-target="#addCommentModal"
                    @click="clearCommentForm()"
                  >
                    <span
                      class="text--capital d-inline-flex mr-2 text-white text--small"
                      >Add new Comment</span
                    >
                    <ion-icon
                      name="add-outline"
                      class="text--600 text--medium text-white"
                    ></ion-icon>
                  </button>
                </div>
                <div>
                  <div
                    class="form__item"
                    v-for="(comment, index) in comments"
                    :key="'comment' + index"
                  >
                    <div class="row">
                      <div class="col-3">
                        <div class="row no-gutters">
                          <div class="col-1">
                            <img
                              src="assets/img/comment-green.svg"
                              alt=""
                              srcset=""
                            />
                          </div>
                          <div class="col-11 px-5">
                            <span
                              class="d-block text--black text--regular text--600"
                              >{{ comment.createdBy }}</span
                            >
                            <span
                              class="text--tiny text--dark text-300 text--opaq1"
                            >
                              {{ comment.lastModifiedDate | fromNow }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="pr-5">
                          <p class="text--small">{{ comment.commentNote }}</p>
                        </div>
                      </div>
                      <div class="col-3 align-self-center">
                        <div
                          class="pl-5 d-flex align-items-center justify-content-end"
                        >
                          <button
                            type="button"
                            class="mx-2 button button--grey text--capital"
                            data-toggle="modal"
                            data-target="#editCommentModal"
                            @click="openEditCommentForm(comment.commentKey)"
                          >
                            <span class="d-inline-flex">edit</span>
                          </button>
                          <button
                            data-toggle="modal"
                            data-target="#deleteCommentModal"
                            type="button"
                            class="mx-2 button button--cancel text--capital"
                            @click="openDeleteCommentForm(comment.commentKey)"
                          >
                            <span class="d-inline-flex">delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Modals -->
    <div
      class="modal modal__right"
      id="approveModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Aproval</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital text--grey d-block mb-3"
                      >Previous State</span
                    >
                    <span class="text--bold text--medium text--capital d-block"
                      >Pending Approval</span
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital text--grey d-block mb-3"
                      >New State</span
                    >
                    <span
                      class="text--bold text--medium text--capital text--success d-block"
                      >Blacklisted</span
                    >
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >Comments</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="modal"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    Approve & Save changes
                  </button>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="rejectModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Reject</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label"
                  >Select Reasons why you are rejecting</label
                >
                <select class="select select--lg" required>
                  <option value="" disabled selected>
                    Select Reasons for reject
                  </option>
                  <option value="">Option 1</option>
                  <option value="">Option 2</option>
                </select>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >Comments</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                  ></textarea>
                </div>
              </div>
              <div class="row mt5">
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital text--grey d-block mb-3"
                      >Previous State</span
                    >
                    <span class="text--bold text--medium text--capital d-block"
                      >Pending Approval</span
                    >
                  </div>
                </div>
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital text--grey d-block mb-3"
                      >New State</span
                    >
                    <span
                      class="text--bold text--medium text--capital text--danger d-block"
                      >Rejected</span
                    >
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="modal"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    Reject & Save changes
                  </button>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="reworkModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Rework</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label"
                  >Select Reasons why you are reworking</label
                >
                <select class="select select--lg" required>
                  <option value="" disabled selected>
                    Select Reasons for rework
                  </option>
                  <option value="">Option 1</option>
                  <option value="">Option 2</option>
                </select>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >Comments</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action d-flex">
                  <button
                    data-dismiss="modal"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    Return to reviewer
                  </button>
                  <button
                    style="background-color: #00ba88"
                    data-dismiss="modal"
                    type="button"
                    class="modal__close ml-auto button form__button form__button--lg w-30"
                  >
                    Return to Queue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editLoanAmount"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editLoanAmountFormCloseButton"
                  aria-label="Close"
                  @click="clearLoanForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit Loan Amount</h5>
          </div>
          <div class="modal__body">
            <form @submit="saveLoanAmount">
              <div class="form__item">
                <label class="form__label">Edit Loan Amount</label>
                <input
                  type="number"
                  class="input input--right form__input form__input--lg"
                  placeholder="Enter loan amount"
                  min="0"
                  step="1"
                  v-model="loanForm.loanAmount"
                  v-bind:class="{
                    'input-required': loanFormErr.loanAmount,
                  }"
                />
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Changes
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearLoanForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editRepayment"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  id="editRepaymentDateFormCloseButton"
                  aria-label="Close"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Edit Repayment Date
            </h5>
          </div>
          <div class="modal__body">
            <form @submit="saveFirstRepaymentDate">
              <div class="form__item">
                <label class="form__label">Repayment Date</label>
                <input
                  type="date"
                  class="input input--right form__input form__input--lg"
                  v-bind:class="{
                    'input-required': loanFormErr.firstRepaymentDate,
                  }"
                  v-model="firstRepaymentDate"
                />
                <!-- :value="
                    new Date(firstRepaymentDate).toISOString().slice(0, 10)
                  " -->
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Changes
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    data-dismiss="modal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editInterest"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editLoanInterestRateFormCloseButton"
                  aria-label="Close"
                  @click="clearLoanForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit Interest Rate</h5>
          </div>
          <div class="modal__body">
            <form @submit="saveLoanInterestRate">
              <div class="form__item">
                <label class="form__label">Interest Rate</label>
                <div class="input-group">
                  <input
                    type="number"
                    class="input input--right form__input form__input--lg"
                    placeholder="Enter interest rate"
                    min="0"
                    step="0.01"
                    v-model="loanForm.interestRate"
                    v-bind:class="{
                      'input-required': loanFormErr.interestRate,
                    }"
                  />
                  <div class="input-group-append">
                    <span
                      class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save Changes
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearLoanForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="regeneratedScheduleModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  id="regeneratedScheduleModal"
                  aria-label="Close"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Generate Schedule</h5>
          </div>
          <div class="modal__body">
            <form v-if="regeneratedSchedule !== null">
              <div class="mt-5 pb5">
                <div class="row py-3 border-bottom align-items-center">
                  <div class="col-3">
                    <span
                      class="text--grey text--tiny d-block text--600 text--capital"
                    >
                      Due Date
                    </span>
                  </div>
                  <div class="col">
                    <span
                      class="text--grey text--tiny d-block text--600 text--capital"
                    >
                      Principal
                    </span>
                  </div>
                  <div class="col">
                    <span
                      class="text--grey text--tiny d-block text--600 text--capital"
                    >
                      Interest
                    </span>
                  </div>
                  <div class="col">
                    <span
                      class="text--grey text--tiny d-block text--600 text--capital"
                    >
                      Fee
                    </span>
                  </div>
                  <div class="col">
                    <span
                      class="text--grey text--tiny d-block text--600 text--capital"
                    >
                      Penalty
                    </span>
                  </div>
                </div>
                <div class="" v-if="regeneratedSchedule !== null">
                  <div
                    class="row py-3 border-bottom align-items-center"
                    v-for="(item, i) in regeneratedSchedule.repayments"
                    :key="i"
                  >
                    <div class="col-3">
                      <span class="table__body__text table__text text--capital">
                        {{ item.installmentDate | moment("DD/MM/YYYY") }}
                      </span>
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        class="input form__input form__input--lg"
                        :value="item.principal"
                        min="0"
                        disabled
                      />
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        class="input form__input form__input--lg"
                        :value="item.interest"
                        min="0"
                        disabled
                      />
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        class="input form__input form__input--lg"
                        :value="item.fee"
                        min="0"
                        disabled
                      />
                    </div>
                    <div class="col">
                      <input
                        type="number"
                        class="input form__input form__input--lg"
                        :value="item.penaltyExpected ? item.penaltyExpected : 0"
                        min="0"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div v-else class="text-center">Loading...</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="loanSchedule"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Regenerate Loan Schedule
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Expected Closure</label>
                <p class="text--black text--600 text--capital text--regular">
                  21-04-2021
                </p>
              </div>
              <div class="mb5">
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        class="table__header__text table__text text--capital text--tiny"
                        style="width: 5%"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Installment Date
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Principal
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Interest
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Fees
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Payment Due
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital text--tiny"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >0</span
                        >
                      </td>
                      <td></td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >1</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >21-01-2021</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦250,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦0.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >2</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >21-01-2021</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦250,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦0.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >3</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >21-01-2021</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦250,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦0.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >4</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >21-01-2021</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦250,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦0.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >5</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >21-01-2021</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦250,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦0.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦50,000.00</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital text--tiny text--grey"
                          >₦1,000,00</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0"></td>
                      <td class="border-0">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--success"
                          >total</span
                        >
                      </td>
                      <td class="border-0">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--success"
                          >₦100,000,000</span
                        >
                      </td>
                      <td class="border-0">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--success"
                          >₦50,000.00</span
                        >
                      </td>
                      <td class="border-0">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--success"
                          >₦0.00</span
                        >
                      </td>
                      <td class="border-0" colspan="2">
                        <span
                          class="table__body__text table__text text--capital text--tiny text--success"
                          >₦50,000.00</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- DocumentModal -->
    <div
      class="modal modal__right"
      id="addDocumentModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="documentFormCloseButton"
                  aria-label="Close"
                  @click="clearDocumentForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add document</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit.prevent="saveDocumentForm">
              <div class="form__item">
                <label class="form__label">Document name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter name"
                  v-model="documentForm.documentName"
                  v-bind:class="{
                    'input-required': documentFormErr.documentName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Document description</label>
                <textarea
                  v-model="documentForm.documentDescription"
                  v-bind:class="{
                    'input-required': documentFormErr.documentDescription,
                  }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <label class="form__label">File</label>
                <input
                  type="file"
                  ref="attachmentFile"
                  class="input form__input form__input--lg"
                  placeholder="Enter file"
                  @click="resetFile()"
                  @change="handleFileUpload()"
                  v-bind:class="{
                    'input-required': documentFormErr.file,
                  }"
                />
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save document
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearDocumentForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editDocumentModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editDocumentFormCloseButton"
                  aria-label="Close"
                  @click="clearDocumentForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit document</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditDocumentForm">
              <div class="form__item">
                <label class="form__label">Document name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter name"
                  v-model="documentForm.documentName"
                  v-bind:class="{
                    'input-required': documentFormErr.documentName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Document description</label>
                <textarea
                  v-model="documentForm.documentDescription"
                  v-bind:class="{
                    'input-required': documentFormErr.documentDescription,
                  }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <label class="form__label">File</label>
                <input
                  type="file"
                  ref="attachmentFileEdit"
                  class="input form__input form__input--lg"
                  placeholder="Enter file"
                  @click="resetFile()"
                  @change="handleFileUpload(true)"
                  v-bind:class="{
                    'input-required': documentFormErr.file,
                  }"
                />
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save document
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearDocumentForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Comment Modals -->
    <div
      class="modal modal__right"
      id="addCommentModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="commentFormCloseButton"
                  aria-label="Close"
                  @click="clearCommentForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Add comment</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit.prevent="saveCommentForm">
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >Comment</label
                >
                <textarea
                  v-model="commentForm.commentNote"
                  v-bind:class="{
                    'input-required': commentFormErr.commentNote,
                  }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save comment
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearCommentForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editCommentModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="editCommentFormCloseButton"
                  aria-label="Close"
                  @click="clearCommentForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit comment</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditCommentForm">
              <div class="form__item">
                <label
                  class="form__label text--regular-alt text--bold text--black"
                  >Comment</label
                >
                <textarea
                  v-model="commentForm.commentNote"
                  v-bind:class="{
                    'input-required': commentFormErr.commentNote,
                  }"
                  cols="5"
                  rows="5"
                  class="input form__input"
                ></textarea>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Save comment
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearCommentForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteCommentModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="modal"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Comment
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to delete this comment
              </h5>
              <p class="form__label">
                Once you delete this comment, it cannot be retrieved.
              </p>
              <div class="d-flex mt-5">
                <button
                  id="deleteCommentCloseButton"
                  data-dismiss="modal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                >
                  cancel
                </button>
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  v-if="!isSaving"
                  @click="deleteComment()"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                >
                  delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Comment Modals -->

    <!-- Status Modal -->
    <div
      class="modal modal__right"
      id="statusModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="statusFormCloseButton"
                  aria-label="Close"
                  @click="clearStatusForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              {{ statusForm.type }}
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveStatusForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <label class="form__label">Status</label>
                  <input
                    v-if="statusForm.type != 'Rework'"
                    type="text"
                    class="input input--right form__input form__input--lg"
                    :disabled="true"
                    v-model="statusForm.status"
                  />
                  <select
                    v-if="statusForm.type == 'Rework'"
                    class="select select--lg"
                    v-model="statusForm.status"
                    v-bind:class="{
                      'input-required': statusFormErr.status,
                    }"
                    disabled
                  >
                    <option :value="null" disabled selected>
                      Select Status
                    </option>
                    <option
                      v-for="item in reworkStatuses"
                      v-bind:key="item"
                      v-bind:value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form__item" v-if="statusForm.type === 'Rework'">
                <div class="form__item mb-0">
                  <div class="form__item mb-0">
                    <label class="form__label">Select Underwriter Level</label>
                    <select
                      class="select select--lg"
                      v-model="selectedQueue"
                      @change="getUnderwriterDetails"
                    >
                      <option :value="null" disabled selected>
                        Select level
                      </option>
                      <option
                        v-for="item in uniqueQueue"
                        :key="item.key"
                        :value="item"
                      >
                        {{ item.approvalLevelName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                class="form__item"
                v-if="
                  statusForm.type != 'Approve' &&
                  statusForm.type != 'Pending Acceptance' &&
                  statusForm.type != 'Approve override'
                "
              >
                <div class="form__item mb-0">
                  <div class="form__item mb-0">
                    <label class="form__label">Add reasons</label>
                    <select
                      class="select select--lg"
                      v-model="statusForm.selectedReason"
                    >
                      <option :value="null" disabled selected>
                        Select reason
                      </option>
                      <!-- <option value="Wrong Documents">Wrong Documents</option>
                      <option value="Incomplete Bank Statement">Incomplete Bank Statement</option> -->
                      <option
                        v-for="item in underwritingReasons"
                        v-bind:key="item.reasonKey"
                        v-bind:value="item"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <div class="px-4">
                      <button
                        type="button"
                        @click="addReasonToStatusForm()"
                        class="mt-3 button page__body__header__button button--grey text--capital"
                      >
                        <span class="d-inline-flex">Add Parameter</span>
                      </button>
                    </div>
                  </div>
                  <div class="form__item mt-5">
                    <label class="form__label">Selected Reasons</label>
                    <div class="form__item_dottedbg px-5 py-3">
                      <div
                        class="d-flex my-3 align-items-center justify-content-between"
                        v-for="reason in statusForm.reasonObjs"
                        v-bind:key="reason.reasonKey + 'selected'"
                      >
                        <p
                          class="text--regular text--black form__item_text3 text--capital"
                        >
                          {{ reason.name }}
                        </p>
                        <button
                          type="button"
                          @click="removeReasonFromStatusForm(reason.reasonKey)"
                          class="mx-2 button button--sm button--cancel text--capital"
                        >
                          <span class="d-inline-flex mr-2">delete</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <textarea
                  cols="5"
                  rows="5"
                  class="input form__input"
                  placeholder="Comment"
                  v-model="statusForm.approvalComment"
                ></textarea>
              </div>
              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    Update Status
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearStatusForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="regeneratedScheduleModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" style="max-width: 900px" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Loan Schedule
            </span>
            <a
              data-dismiss="modal"
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <!-- <span class="modal__header__subtitle text--capital">
            For Under writing Queue
          </span> -->
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <a v-if="isRegenerating" role="button">
              <div class="spinner-border text-dark" role="status"></div>
            </a>
            <div v-if="!isRegenerating && regeneratedSchedule != null">
              <div class="d-flex">
                <span class="d-inline-flex mr-2">
                  <label>Total Principal</label>: ₦{{
                    regeneratedSchedule.totalprincipal | formatCurrencyPipe
                  }}
                </span>
                <span class="d-inline-flex mr-2">
                  <label>Total Interest</label>: ₦{{
                    regeneratedSchedule.totalInterest | formatCurrencyPipe
                  }}
                </span>
                <span class="d-inline-flex mr-2">
                  <label>Total Payment Due</label>: ₦{{
                    regeneratedSchedule.totalPaymentDue | formatCurrencyPipe
                  }}
                </span>
              </div>
              <div class="row">
                <table class="customtable w-100">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        installment
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        installment date
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        principal
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        interest
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        payment due
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        balance
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        Days Count
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        Interest Period
                      </th>
                      <th
                        scope="col"
                        class="table__header__text table__text text--capital"
                      >
                        Principal Collect Schedule
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in regeneratedSchedule.repayments"
                      v-bind:key="item.installment + '' + index"
                    >
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ item.installment }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                        >
                          {{ item.installmentDate | formatDatePipe }}
                        </span>
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >₦{{ item.principal | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >₦{{ item.interest | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >₦{{ item.paymentDue | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >₦{{ item.balance | formatCurrencyPipe }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ item.daysCount }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ item.interestPeriod }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="table__body__text table__text text--capital"
                          >{{ item.principalCollectSchedule }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="d-flex mt-5">
              <button
                id="regeneratedScheduleModalClose"
                data-dismiss="modal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
              >
                close
              </button>
              <!-- <a
                role="button"
                class="button form__button form__button--lg w-25 save-changes"
                v-if="isSaving"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!-- Add Field -->
    <AddFieldModal
      :account="loanAccountDetails"
      :enums="newEnums"
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :loadingAccountCustomFieldSets="uiProps.loadingAccountCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :accountCustomFieldSets="uiProps.accountCustomFieldSets"
      :show="openAddField"
      @close-add-field="toggleAddField"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @reload="getAccountCustomFieldSets"
      @get-activities="getLoanAccountDetails()"
    />

    <!-- Edit custom field -->
    <EditFieldModal
      :account="loanAccountDetails"
      :loadingCustomFieldSets="uiProps.loadingCustomFieldSets"
      :loadingAccountCustomFieldSets="uiProps.loadingAccountCustomFieldSets"
      :customFieldSets="uiProps.customFieldSets"
      :accountCustomFieldSets="uiProps.accountCustomFieldSets"
      :customFieldSetKey="uiProps.customFieldSetKey"
      :customFieldKey="uiProps.customFieldKey"
      :customFieldValue="uiProps.customFieldValue"
      :customFields="uiProps.customFields"
      :linkValue="uiProps.linkFieldValue ? uiProps.linkFieldValue : null"
      :searchArray="
        uiProps.searchValue && uiProps.searchValue.length
          ? uiProps.searchValue
          : []
      "
      :showEditField="uiProps.editField"
      @alertError="alertError"
      @alertSuccess="alertSuccess"
      @reload="getAccountCustomFieldSets"
      @close-edit-field="closeEditCustomFieldModal"
    />

    <!-- Preview modal -->
    <previewModal
      :show="showPreview"
      @close="togglePreviewModal"
      :imagePreviewDataName="imagePreviewDataName"
      :imagePreviewDataType="imagePreviewDataType"
    />

    <!-- Attachment -->
    <DeleteModal :show="deleteAttachment" @close="closeDeleteAttachmentModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Delete Document
            </h5>
            <p>
              Please confirm that you really want to delete this document
              parmanently
            </p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeleteAttachmentModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                v-if="isDeleting"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes modal__confirm__button--delete fitrem"
                @click="onAttachmentDelete"
                v-else
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
// import { mapState } from "vuex";
import commentService from "@/core/services/comment-service/comment.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import otherService from "@/core/services/other-service/other.service";
import documentAttachmentService from "@/core/services/general-setup-service/document-attachment.service";
import fileUploadService from "@/core/services/other-service/file-upload.service";
import underwritingService from "@/core/services/underwriting/underwriting.service";
import underwritingReasonService from "@/core/services/underwriting/underwriting-reason.service";
import UnderwritingAside from "@/view/pages/clients/customers/loan_deposit_customer_aside.vue";
import branchesService from "@/core/services/organization-service/branches.service";
import ApiService from "@/core/services/api.service";
import AccessApiService from "@/core/services/access.service";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { mapState } from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import { Role } from "@/core/services/role.js";
import AddFieldModal from "@/view/pages/loans/account_details/components/modals/addFieldModal";
import SecondLevelDetails from "@/view/pages/loans/account_details/components/secondLevelDetails";
import EditFieldModal from "@/view/pages/loans/account_details/components/modals/editFieldModal.vue";

import GeneralApiService from "@/core/services/general.service";
import DepositApiService from "@/core/services/api.service";

export default Vue.extend({
  name: "underwriting_accounts",
  components: {
    UnderwritingAside,
    DeleteModal,
    AddFieldModal,
    SecondLevelDetails,
    EditFieldModal,
    previewModal: () =>
      import(
        /* WebpackChunkName: "previewModal" */ "@/components/deposit/preview/preview.vue"
      ),
  },
  data: function () {
    return {
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      selectedQueue: {},
      reworkStatuses: ["ReworkQueue"],
      showApproveButton: false,
      loanRejected: false,
      loanApprove: false,
      openAddField: false,

      showPreview: false,

      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isDeactivating: false,

      task: null,
      taskKey: null,
      loanAccountId: null,
      loanAccountDetails: null,
      loanAccountDetailsSchedule: null,
      loanAccountDetailsTransactions: null,
      loanAccountDetailsActivities: null,
      loanAccountDetailsAttachments: null,
      loanAccountDetailsComments: null,
      customerInfo: null,
      loanProduct: null,
      comments: null,

      deleteAttachment: false,
      deleteKey: "",

      schedule: null,
      scheduleFetchModel: {
        pageIndex: 1,
        pageSize: 5,
      },
      schedulePaging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      newFees: [],
      newLoanFees: [],
      amountExpected: null,
      amountExpectedFetchModel: {
        pageIndex: 1,
        pageSize: 5,
      },
      amountExpectedPaging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },

      amountPaid: null,
      firstRepaymentDate: null,
      repaymentInstallments: null,
      amountPaidFetchModel: {
        pageIndex: 1,
        pageSize: 5,
      },
      amountPaidPaging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },

      amountDue: null,
      amountDueFetchModel: {
        pageIndex: 1,
        pageSize: 5,
      },
      amountDuePaging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },

      showSchedule: true,
      showAmountExpected: true,
      showAmountPaid: true,
      showAmountDue: true,

      commentForm: {
        commentSource: "Loan",
        parentKey: null,
        userKey: null,
        commentNote: null,
      },
      commentFormErr: {
        commentNote: false,
      },
      commentToDelete: null,

      statusForm: {
        taskKey: null,
        status: null,
        approvalComment: "",
        accessUserKey: null,
        reasonObjs: [],
        reasons: [],
        selectedReason: null,
        underwriters: [],
      },
      statusFormErr: {
        status: false,
      },
      underwritingReasons: [],
      loanForm: {
        id: null,
        loanAmount: null,
        repaymentPeriodCount: null,
        repaymentPeriodUnit: null,
        repaymentInstallments: null,
        gracePeriod: null,
        interestRate: null,
        interestSpread: null,
        principalRepaymentInterval: null,
        penaltyRate: null,
        periodicPayment: null,
        arrearsTolerancePeriod: null,
        disbursementDetails: null,
        scheduleSettings: null,
        productTypeKey: null,
        loanName: null,
      },
      loanFormErr: {
        id: false,
        loanAmount: false,
        repaymentPeriodCount: false,
        repaymentPeriodUnit: false,
        repaymentInstallments: false,
        gracePeriod: false,
        interestRate: false,
        interestSpread: false,
        principalRepaymentInterval: false,
        penaltyRate: false,
        periodicPayment: false,
        arrearsTolerancePeriod: false,
        firstRepaymentDate: false,
      },
      branch: null,

      documents: [],
      documentFetchModel: {
        holderKey: null,
        pageIndex: 1,
        pageSize: 5,
      },
      documentPaging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      documentForm: {
        documentHolderKey: null,
        documentHoldertYpe: "LoanAccount",
        documentKey: null,
        documentDescription: null,
        documentFileSize: 0,
        identificationDocumentKey: null,
        documentName: null,
        documentOriginalFileName: null,
        documentType: null,
        documentLocation: null,
        file: null,
      },
      documentFormErr: {
        documentName: false,
        file: false,
      },
      isRegenerating: false,
      regeneratedSchedule: null,
      paidRepayment: false,
      attachmentName: "",
      attachmentType: "",

      imagePreviewDataType: "",
      imagePreviewDataName: "",
      allUsers: [],
      currentUser: {},
      fRDate: "",
      customerLoanDeposits: [],

      uiProps: {
        editField: false,
        loadingCustomFieldSets: false,
        loadingAccountCustomFieldSets: false,
        customFieldSets: [],
        accountCustomFieldSets: [],
        customFieldSetKey: null,
        customFieldKey: null,
        customFieldValue: null,
        customFields: [],
      },
    };
  },
  methods: {
    toggleAddField(close = false) {
      if (!close) this.getCustomFieldSets();
      this.openAddField = !this.openAddField;
    },
    getUnderwriterDetails() {
      const queue = [
        {
          approvalLevelValue: this.selectedQueue.approvalLevel,
          approvalLevelName: this.selectedQueue.approvalLevelName,
          approvalLevelUserKey: this.selectedQueue.approvalLevelUserKey,
          hasOfferLetter: true,
          orderOfApproval: 0,
          fullName: "",
          email: "",
          users: [],
        },
      ];

      this.statusForm.underwriters = queue;
    },
    getCustomFieldSets() {
      this.alert.show = false;
      const self = this;
      self.uiProps.loadingCustomFieldSets = true;
      const p = {
        customFieldSetType: "LoanAccounts",
      };
      GeneralApiService.post("CustomFields/FieldSets", p)
        .then((res) => res.data.data.items)
        .then((res) => {
          self.uiProps.loadingCustomFieldSets = false;
          self.uiProps.customFieldSets = res;
        })
        .catch(() => {
          self.uiProps.loadingCustomFieldSets = false;
        });
    },
    getAccountCustomFieldSets() {
      this.alert.show = false;
      const self = this;
      self.uiProps.loadingAccountCustomFieldSets = true;
      GeneralApiService.get(
        "CustomFields/FieldValues/" + this.$route.params.id + "/LoanAccounts"
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          self.uiProps.loadingAccountCustomFieldSets = false;
          self.uiProps.accountCustomFieldSets = res;
        });
    },
    async editCustomField(key, keySub, val, data) {
      const getField = data.find((field) => field.fieldKey == keySub);
      const fieldDataType = getField.dataType;
      if (
        ["customer link", "group link", "user link"].includes(
          fieldDataType.toLowerCase()
        )
      ) {
        const linkKey = getField.value.length
          ? getField.value.split("-").at(-2)
          : "";
        const linkName = getField.value.length
          ? getField.value.split("-").at(-1)
          : "";
        val = linkKey;

        let linkFieldValue = { name: linkName, linkKey: linkKey };
        let searchQuery = linkName.split(" ")[0];
        let searchValue = [];

        let searchArea = "";
        fieldDataType.toLowerCase() == "customer link"
          ? "customer"
          : fieldDataType.toLowerCase() == "group link"
          ? "group"
          : "user";

        try {
          const response = await ApiService.post(`GlobalSearch`, {
            searchQuery,
            searchCategory: searchArea,
          });
          searchValue = response.data.data;
        } catch (e) {
          searchValue = [];
        }
        this.uiProps = { ...this.uiProps, linkFieldValue, searchValue };
      } else {
        this.uiProps = this.uiProps;
      }
      this.uiProps.customFieldSetKey = key;
      this.uiProps.customFieldKey = keySub;
      this.uiProps.customFieldValue = val;
      this.uiProps.customFields = data;
      this.uiProps.editField = true;
      // this.$refs.editCustomField.click();
    },
    closeEditCustomFieldModal() {
      this.uiProps.editField = !this.uiProps.editField;
    },

    getAttachmentName(key) {
      this.attachmentName = "";
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByDocumentkey?documentKey=${key}`
      )
        .then((response) => {
          this.attachmentName = response.data.data.document_original_file_name;
          this.attachmentType = response.data.data.document_type;
        })
        .catch((error) => {
          this.$emit("download-error", error);
          // this.alertError(error.response.data.message);
        });
    },
    updateRepaymentDate(e) {
      const date = e.target.value;
      this.firstRepaymentDate = date;
      this.fRDate = date;
    },
    async getLoanAccountDetails(reloadCus = false) {
      this.isSaving = true;
      try {
        const res = await otherService.getLoanAccountDetailsById(
          this.loanAccountId
        );
        this.loanAccountDetails = res.data.data;
        this.isSaving = false;

        this.firstRepaymentDate = moment(
          this.loanAccountDetails.anticipatedDisbursementDetails
            .firstRepaymentDate
        ).format("YYYY-MM-DD");

        this.loanRejected = this.loanAccountDetails.accountState == "REJECTED";

        this.loanForm.id = this.loanAccountId;
        this.loanForm.loanAmount = this.loanAccountDetails.loanAmount;
        this.loanForm.loanName = this.loanAccountDetails.loanName;
        this.loanForm.repaymentPeriodCount = 0; // null; // this.loanAccountDetails;
        this.loanForm.repaymentPeriodUnit =
          this.loanAccountDetails.adminLoanProduct.repayment_period_unit;
        this.loanForm.repaymentInstallments = 0; // null; // this.loanAccountDetails;
        this.loanForm.gracePeriod = 0; // null; // this.loanAccountDetails;
        this.loanForm.interestRate = this.loanAccountDetails.interestRate;
        this.loanForm.interestSpread =
          this.loanAccountDetails.interestSettings.interestSpread;
        this.loanForm.principalRepaymentInterval = 0; // null; // this.loanAccountDetails;
        this.loanForm.penaltyRate = 0; // this.loanAccountDetails.penaltySettings.penaltyRate;
        this.loanForm.periodicPayment = 0; // this.loanAccountDetails;
        this.loanForm.arrearsTolerancePeriod =
          this.loanAccountDetails.arrearsTolerancePeriod;
        this.loanForm.disbursementDetails =
          this.loanAccountDetails?.disbursementDetails;
        this.loanForm.productTypeKey = this.loanAccountDetails.productTypeKey;

        // this.firstRepaymentDate = this.getDate(
        //   this.loanAccountDetails?.disbursementDetails?.firstRepaymentDate
        // );

        if (reloadCus)
          this.getCustomerDetails(this.loanAccountDetails.accountHolderKey);
        // this.getLoanProductById(
        //   this.loanAccountDetails.adminLoanProduct.loan_product_key
        // );
        this.getLoansNotRequired(
          this.loanAccountDetails.loanProductFees.loanProductFees
        );
        // if (this.loanAccountDetails.assignedBranchKey != null) {
        //   this.getBranchDetails(this.loanAccountDetails.assignedBranchKey);
        // }
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getLoanAccountDetailsSchedule() {
      // schedule amountExpected amountDue amountPaid
      try {
        this.loanAccountDetailsSchedule = null;
        if (this.schedule) return;
        this.isSaving = true;
        // if (type == null) {
        const res = await otherService.getLoanAccountDetailsScheduleById({
          loanAccountId: this.loanAccountId,
        });
        const alldata = res.data.data;

        this.schedule = alldata;

        // this.schedule = alldata.schedule.data;
        // this.schedulePaging = alldata.schedule;
        // this.schedulePaging.pageIndex = this.schedulePaging.currentPage;
        // this.schedulePaging.hasPreviousPage = this.schedulePaging.hasPrevious;
        // this.schedulePaging.hasNextPage = this.schedulePaging.hasNext;

        // this.amountExpected = alldata.amountExpected.data;
        // this.amountExpectedPaging = alldata.amountExpected;
        // this.amountExpectedPaging.pageIndex = this.amountExpectedPaging.currentPage;
        // this.amountExpectedPaging.hasPreviousPage = this.amountExpectedPaging.hasPrevious;
        // this.amountExpectedPaging.hasNextPage = this.amountExpectedPaging.hasNext;

        // this.amountPaid = alldata.amountPaid.data;
        // this.amountPaidPaging = alldata.amountPaid;
        // this.amountPaidPaging.pageIndex = this.amountPaidPaging.currentPage;
        // this.amountPaidPaging.hasPreviousPage = this.amountPaidPaging.hasPrevious;
        // this.amountPaidPaging.hasNextPage = this.amountPaidPaging.hasNext;

        // this.amountDue = alldata.amountDue.data;
        // this.amountDuePaging = alldata.amountDue;
        // this.firstRepaymentDate = this.getDate(this.amountDue[0]?.dueDate);
        // this.amountDuePaging.pageIndex = this.amountDuePaging.currentPage;
        // this.amountDuePaging.hasPreviousPage = this.amountDuePaging.hasPrevious;
        // this.amountDuePaging.hasNextPage = this.amountDuePaging.hasNext;
        // } else if (type == "schedule") {
        //   const res = await otherService.getLoanAccountDetailsScheduleById({
        //     loanAccountId: this.loanAccountId,
        //   });
        //   const alldata = res.data;

        //   // this.schedule = alldata.schedule.data;
        //   // this.schedulePaging = alldata.schedule;
        //   // this.schedulePaging.pageIndex = this.schedulePaging.currentPage;
        //   // this.schedulePaging.hasPreviousPage = this.schedulePaging.hasPrevious;
        //   // this.schedulePaging.hasNextPage = this.schedulePaging.hasNext;
        // } else if (type == "amountExpected") {
        //   const res = await otherService.getLoanAccountDetailsScheduleById(
        //     this.amountExpectedFetchModel
        //   );
        //   const alldata = res.data;

        //   this.amountExpected = alldata.amountExpected.data;
        //   this.amountExpectedPaging = alldata.amountExpected;
        //   this.amountExpectedPaging.pageIndex = this.amountExpectedPaging.currentPage;
        //   this.amountExpectedPaging.hasPreviousPage = this.amountExpectedPaging.hasPrevious;
        //   this.amountExpectedPaging.hasNextPage = this.amountExpectedPaging.hasNext;
        // } else if (type == "amountDue") {
        //   const res = await otherService.getLoanAccountDetailsScheduleById(
        //     this.amountDueFetchModel
        //   );
        //   const alldata = res.data;

        //   this.amountDue = alldata.amountDue.data;
        //   this.firstRepaymentDate = this.getDate(this.amountDue[0].dueDate);
        //   this.amountDuePaging = alldata.amountDue;
        //   this.amountDuePaging.pageIndex = this.amountDuePaging.currentPage;
        //   this.amountDuePaging.hasPreviousPage = this.amountDuePaging.hasPrevious;
        //   this.amountDuePaging.hasNextPage = this.amountDuePaging.hasNext;
        // } else if (type == "amountPaid") {
        //   const res = await otherService.getLoanAccountDetailsScheduleById(
        //     this.amountPaidFetchModel
        //   );
        //   const alldata = res.data;

        //   this.amountPaid = alldata.amountPaid.data;
        //   this.amountPaidPaging = alldata.amountPaid;
        //   this.amountPaidPaging.pageIndex = this.amountPaidPaging.currentPage;
        //   this.amountPaidPaging.hasPreviousPage = this.amountPaidPaging.hasPrevious;
        //   this.amountPaidPaging.hasNextPage = this.amountPaidPaging.hasNext;
        // }
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    // async getLoanAccountDetailsTransactions() {
    //   try {
    //     this.loanAccountDetailsTransactions = null;
    //     this.isLoading = true;

    //     const res = await otherService.getLoanAccountDetailsTransactionsById(
    //       this.loanAccountId
    //     );
    //     this.loanAccountDetailsTransactions = res.data.data;

    //     this.isLoading = false;
    //   } catch (e) {
    //     this.isLoading = false;
    //     const error = e.response.data.message;
    //     this.alertError(error);
    //   }
    // },
    async getLoanAccountDetailsActivities() {
      try {
        if (this.loanAccountDetailsActivities) return;
        this.isSaving = true;

        const res = await otherService.getLoanAccountDetailsActivitiesById(
          this.loanAccountId
        );
        this.loanAccountDetailsActivities = res.data.data;

        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.message;
        this.alertError(error);
      }
    },
    async getLoanAccountDetailsAttachments() {
      try {
        if (this.loanAccountDetailsAttachments) return;
        this.isSaving = true;

        const res = await otherService.getLoanAccountDetailsAttachmentsById(
          this.loanAccountId
        );
        this.loanAccountDetailsAttachments = res.data.data;

        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async downloadFile(key) {
      await this.getAttachmentName(key);
      ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.attachmentName || "file");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.alertError(error);
        });
    },
    openDeleteAttachmentModal(del) {
      this.deleteAttachment = true;
      this.deleteKey = del;
    },
    closeDeleteAttachmentModal() {
      this.deleteAttachment = false;
    },
    onAttachmentDelete() {
      this.isDeleting = true;
      ApiService.delete(`GeneralDocument/delete?documentKey=${this.deleteKey}`)
        .then((response) => {
          this.alertSuccess(response.data.message);
          this.loanAccountDetailsAttachments = null;
          this.isDeleting = false;
          this.deleteAttachment = false;
          // this.getLoanDocuments();
          this.getLoanAccountDetailsAttachments();
        })
        .catch((error) => {
          this.isDeleting = false;
          this.alertError(error.response.data.message);
        });
    },
    previewFile(key) {
      const loanKey = this.$route.params.id;
      this.showPreview = !this.showPreview;
      ApiService.get(
        `GeneralDocument/PreviewDocument?documentKey=${key}&loanKey=${loanKey}`
      )
        .then((response) => {
          this.imagePreviewDataType = response.data.data.fileType;
          this.imagePreviewDataName = response.data.data.fileUrl;
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    togglePreviewModal() {
      this.showPreview = !this.showPreview;
    },
    async getLoanAccountDetailsComments() {
      try {
        this.isLoading = true;

        const res = await otherService.getLoanAccountDetailsCommentsById(
          this.loanAccountId
        );
        this.loanAccountDetailsComments = res.data.data;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCustomerDetails(customerKey) {
      this.isLoading = true;
      try {
        const res = await customerService.getCustomerByKey(customerKey);
        this.customerInfo = res.data.data;
        const middleName = this.customerInfo.customerMiddleName
          ? this.customerInfo.customerMiddleName
          : "";
        this.customerInfo.customerFullname = `${this.customerInfo.customerFirstName} ${middleName} ${this.customerInfo.customerLastName}`;
        this.getCustomerLoanDeposits(this.customerInfo.customerKey);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    imageAlert({ type, message }) {
      if (type === "error") {
        this.alertError(message);
      }

      if (type === "success") {
        this.alertSuccess(message);
      }

      if (type === "warning") {
        this.alertWarning(message);
      }
    },
    async getCustomerLoanDeposits(key) {
      this.isLoading = true;
      try {
        const res = await otherService.getAllLoanDepositAccount(key);
        this.customerLoanDeposits = res.data.data;
        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.closedAccount.length > 0
        ) {
          this.customerLoanDeposits.closedAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }

        if (
          this.customerLoanDeposits &&
          this.customerLoanDeposits.operationalAccount.length > 0
        ) {
          this.customerLoanDeposits.operationalAccount.forEach((item) => {
            this.depositTotal += item.balance;
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getBranchDetails(branchKey) {
      this.isLoading = true;
      try {
        const res = await branchesService.getByKey(branchKey);
        this.branch = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getLoanProductById(productKey) {
      this.isLoading = true;
      try {
        const res = await otherService.getLoanProductById(productKey);
        this.loanProduct = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getLoanComments() {
      try {
        if (this.comments) return;
        this.isSaving = true;
        const res = await commentService.getByParentKey(this.loanAccountId);
        this.comments = res.data.data.items;
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getLoanDocuments() {
      try {
        this.isSaving = true;
        const res = await documentAttachmentService.get(
          this.documentFetchModel
        );
        this.documents = res.data.data.items;
        this.documentPaging = res.data.data;
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getReasons() {
      try {
        this.isLoading = true;
        const res = await underwritingReasonService.get({
          status: "Active",
          pageIndex: 0,
          pageSize: 100,
        });
        this.underwritingReasons = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getUnderwritingTask() {
      try {
        this.task = null;
        this.isLoading = true;
        const res = await underwritingService.getUnderwritingTaskByKey(
          this.taskKey
        );
        this.task = res.data.data;

        // colors
        this.task.firstGreen = this.task.firstApprovalReviewStatus == "Approve";
        this.task.firstRed = this.task.firstApprovalReviewStatus == "Reject";
        this.task.firstOrange =
          this.task.firstApprovalReviewStatus != "Approve" &&
          this.task.firstApprovalReviewStatus != "Reject";

        this.task.secondGreen =
          this.task.secondApprovalReviewStatus == "Approve";
        this.task.secondRed = this.task.secondApprovalReviewStatus == "Reject";
        this.task.secondOrange =
          this.task.secondApprovalReviewStatus != "Approve" &&
          this.task.secondApprovalReviewStatus != "Reject";

        this.loanApprove = this.task.approverStatus == "Approve";

        // this.showApproveButton =
        //   (this.task.approvalType == "Primary approval" &&
        //     this.task.firstApprovalReviewStatus == "Awaiting action") ||
        //   (this.task.approvalType == "Secondary approval" &&
        //     this.task.secondApprovalReviewStatus == "Awaiting action");
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    // Comment
    openEditCommentForm(commentKey) {
      this.clearCommentForm();
      this.clearCommentFormValidation();

      const comment = this.comments.find((x) => x.commentKey == commentKey);
      if (comment != null && comment != undefined) {
        this.commentForm = {
          commentKey: comment.commentKey,
          commentSource: "Loan",
          parentKey: comment.parentKey,
          userKey: comment.userKey,
          commentNote: comment.commentNote,
        };
      }
    },
    openDeleteCommentForm(commentKey) {
      this.commentToDelete = commentKey;
    },
    saveCommentForm: async function (e) {
      e.preventDefault();
      if (this.validateCommentForm()) {
        try {
          this.isSaving = true;
          await commentService.create(this.commentForm);
          this.comments = null;
          this.getLoanComments();
          this.loanAccountDetailsActivities = null;
          // this.getLoanAccountDetailsActivities();
          this.clearCommentForm();
          document.getElementById("commentFormCloseButton").click();
          this.alertSuccess("Comment saved");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEditCommentForm: async function (e) {
      e.preventDefault();
      if (this.validateCommentForm()) {
        try {
          this.isSaving = true;
          await commentService.put(this.commentForm);
          this.comments = null;
          this.getLoanComments();
          this.clearCommentForm();
          this.loanAccountDetailsActivities = null;
          // this.getLoanAccountDetailsActivities();
          this.alertSuccess("Comment updated");
          this.isSaving = false;
          document.getElementById("editCommentFormCloseButton").click();
        } catch (e) {
          this.isSaving = false;
          // const error = e.response.data.message || e.response.data.data;
          this.alertError(e);
        }
      } else {
        this.alertWarning("Kindly add a comment.");
      }
    },
    deleteComment: async function () {
      try {
        this.isSaving = true;
        // await commentService.del(this.commentToDelete);
        const p = {
          commentKey: this.commentToDelete,
        };
        await commentService.del(p);
        this.comments = null;
        this.getLoanComments();
        this.loanAccountDetailsActivities = null;
        // this.getLoanAccountDetailsActivities();
        document.getElementById("deleteCommentCloseButton").click();
        this.alertSuccess("Deleted.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message || e.response.data.data;
        this.alertError(error);
      }
    },
    clearCommentForm() {
      this.commentForm = {
        commentSource: "Loan",
        parentKey: this.loanAccountId,
        userKey: null,
        commentNote: null,
      };
    },
    validateCommentForm() {
      this.clearCommentFormValidation();
      if (this.commentForm.commentNote) {
        return true;
      }

      for (const key in this.commentFormErr) {
        if (Object.hasOwnProperty.call(this.commentFormErr, key)) {
          if (
            this.commentForm[key] == null ||
            this.commentForm[key] == undefined ||
            this.commentForm[key].trim() == ""
          ) {
            this.commentFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearCommentFormValidation() {
      this.commentFormErr = {
        commentNote: false,
      };
    },
    // Document Form
    editDocument(documentKey) {
      let doc = this.documents.find((x) => x.documentKey == documentKey);
      if (doc != null) {
        this.clearDocumentForm();
        Object.assign(this.documentForm, doc);
      }
    },
    saveDocumentForm: async function (e) {
      e.preventDefault();
      if (this.validateDocumentForm()) {
        this.isSaving = true;
        //   let formData = new FormData();
        //   formData.append("file", this.documentForm.file);
        //   const title = this.documentForm.documentName;

        //   const resFile = await fileUploadService.uploadFile(
        //     this.$route.params.id,
        //     formData,
        //     title,
        //     description
        //   );
        //   if (resFile["data"]["status"] != true) {
        //     this.isSaving = false;
        //     this.alertError("File upload");
        //     return;
        //   }
        //   await documentAttachmentService.create(this.documentForm);
        //   this.clearDocumentForm();
        //   this.getLoanAccountDetailsAttachments();
        //   document.getElementById("documentFormCloseButton").click();
        //   this.alertSuccess("Document saved");
        //   this.getLoanAccountDetailsAttachments();
        //   this.isSaving = false;

        const formData = new FormData();
        const loanKey = this.$route.params.id;
        formData.append("file", this.documentForm.file);
        formData.append("description", this.documentForm.documentDescription);
        DepositApiService.post(
          `GeneralDocument/CreateAttachment/${loanKey}?accountType=Underwriting&documentTitle=${this.documentForm.documentName}&loanKey=${loanKey}`,
          formData
        ).then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.isSaving = false;
            this.alertSuccess("File upload");
            this.loanAccountDetailsAttachments = null;
            this.clearDocumentForm();
            // documentAttachmentService.create(this.documentForm);
            this.getLoanAccountDetailsAttachments();
            document.getElementById("documentFormCloseButton").click();
          } else {
            this.alertError("Unable to save Attachment currently");
          }
        });
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEditDocumentForm: async function (e) {
      e.preventDefault();
      if (this.validateDocumentForm()) {
        try {
          this.isSaving = true;
          let formData = new FormData();
          formData.append("file", this.documentForm.file);

          const resFile = await fileUploadService.uploadFile(
            this.$route.params.taskKey,
            formData
          );
          if (resFile["data"]["status"] != true) {
            this.isSaving = false;
            this.alertError("File upload");
            return;
          }
          const payload = {
            document_key: this.documentForm.documentKey,
            document_holder_key: this.documentForm.documentHolderKey,
            document_holdert_ype: this.documentForm.documentHoldertYpe,
            document_description: this.documentForm.documentDescription,
            document_file_size: this.documentForm.documentFileSize,
            key: this.document.identificationDocumentKey,
            document_name: this.documentForm.documentName,
            document_original_file_name:
              this.documentForm.documentOriginalFileName,
            document_type: this.document.documentType,
            document_location: this.document.documentLocation,
            file: this.documentForm.file,
          };
          await documentAttachmentService.update(payload);
          this.loanAccountDetailsAttachments = null;
          this.clearDocumentForm();
          this.getLoanAccountDetailsAttachments();
          document.getElementById("editDocumentFormCloseButton").click();
          this.alertSuccess("Document updated");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    resetFile() {
      this.$refs.attachmentFile.value = null;
      this.$refs.attachmentFileEdit.value = null;
    },
    handleFileUpload(edit = false) {
      let file = this.$refs.attachmentFile.files[0];
      if (edit) {
        file = this.$refs.attachmentFileEdit.files[0];
      }
      if (file == null || file == undefined) {
        this.documentForm.file = null;
        this.documentForm.documentFileSize = null;
        this.documentForm.documentOriginalFileName = null;
        this.documentForm.documentType = null;
      } else {
        this.documentForm.file = file;
        this.documentForm.documentFileSize = file.size / 1024;
        this.documentForm.documentOriginalFileName = file.name;
        this.documentForm.documentType = file.type;
      }
    },
    clearDocumentForm() {
      this.documentForm = {
        documentHolderKey: this.loanAccountId,
        documentHoldertYpe: "LoanAccount",
        documentKey: null,
        documentDescription: null,
        documentFileSize: 0,
        identificationDocumentKey: null,
        documentName: null,
        documentOriginalFileName: null,
        documentType: null,
        documentLocation: `LoanAccount${this.loanAccountId}`,
        file: null,
      };
      this.resetFile();
    },
    validateDocumentForm() {
      this.clearDocumentFormValidation();
      if (this.documentForm.file != null && this.documentForm.documentName) {
        return true;
      }

      for (const key in this.documentFormErr) {
        if (Object.hasOwnProperty.call(this.documentFormErr, key)) {
          if (
            this.documentForm[key] == null ||
            this.documentForm[key] == undefined ||
            this.documentForm[key].trim() == ""
          ) {
            this.documentFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearDocumentFormValidation() {
      this.documentFormErr = {
        documentName: false,
        file: false,
      };
    },
    // Edit Loan Form
    saveLoanAmount: async function (e) {
      e.preventDefault();
      const { id, loanAmount } = this.loanForm;
      const formLoan = { loanAccountKey: id, loanAmount: loanAmount };
      if (this.validateLoanForm("LoanAmount")) {
        try {
          this.isSaving = true;
          const res = await otherService.editLoanAmount(formLoan);
          this.getLoanAccountDetails();
          this.schedule = null;
          this.loanAccountDetailsActivities = null;
          // this.getLoanAccountDetailsSchedule();
          // this.getLoanAccountDetailsTransactions();
          // this.getLoanAccountDetailsActivities();
          this.clearLoanForm();
          document.getElementById("editLoanAmountFormCloseButton").click();
          this.alertSuccess(res.data.message);
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    saveLoanInterestRate: async function (e) {
      e.preventDefault();
      const { id, interestRate } = this.loanForm;
      const formLoan = { loanAccountKey: id, interestRate: interestRate };
      if (this.validateLoanForm("InterestRate")) {
        try {
          this.isSaving = true;
          const res = await otherService.editInterestRate(formLoan);
          this.getLoanAccountDetails();
          this.schedule = null;
          this.loanAccountDetailsActivities = null;
          // this.getLoanAccountDetailsSchedule();
          // this.getLoanAccountDetailsTransactions();
          // this.getLoanAccountDetailsActivities();
          this.clearLoanForm();
          document
            .getElementById("editLoanInterestRateFormCloseButton")
            .click();
          this.alertSuccess(res.data.message);
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    saveFirstRepaymentDate: async function (e) {
      e.preventDefault();
      if (this.validateLoanForm("RepaymentDate")) {
        try {
          this.isSaving = true;
          const model = {
            loanKey: this.loanForm.id,
            loanAmount: this.loanForm.loanAmount,
            interestRate: this.loanForm.interestRate,
            firstRepaymentDate: this.firstRepaymentDate,
          };
          await otherService.editLoanAccountDetails(model);
          this.getLoanAccountDetails();
          this.schedule = null;
          this.loanAccountDetailsActivities = null;
          // this.getLoanAccountDetailsTransactions();
          // this.getLoanAccountDetailsActivities();
          this.clearLoanForm();
          // this.getLoanAccountDetailsSchedule();
          document.getElementById("editRepaymentDateFormCloseButton").click();
          this.alertSuccess("Repayment date updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    validateLoanForm(type) {
      this.clearLoanFormValidation();
      switch (type) {
        case "LoanAmount":
          if (this.loanForm.loanAmount) return true;
          this.loanFormErr.loanAmount = true;
          return false;
        case "InterestRate":
          if (this.loanForm.interestRate) return true;
          this.loanFormErr.interestRate = true;
          return false;
        case "RepaymentDate":
          if (this.firstRepaymentDate) return true;
          this.loanFormErr.firstRepaymentDate = true;
          return false;
        default:
          break;
      }
    },
    clearLoanForm() {
      this.loanForm = {};
      this.loanForm.id = this.loanAccountId;
      this.loanForm.loanAmount = this.loanAccountDetails.loanAmount;
      this.loanForm.repaymentPeriodCount = 0; // null; // this.loanAccountDetails;
      this.loanForm.repaymentPeriodUnit =
        this.loanAccountDetails.adminLoanProduct.repayment_period_unit;
      this.loanForm.repaymentInstallments = 0; // null; // this.loanAccountDetails;
      this.loanForm.gracePeriod = 0; // null; // this.loanAccountDetails;
      this.loanForm.interestRate = Number(this.loanAccountDetails.interestRate);
      this.loanForm.interestSpread =
        this.loanAccountDetails.interestSettings.interestSpread;
      this.loanForm.principalRepaymentInterval = 0; // null; // this.loanAccountDetails;
      this.loanForm.penaltyRate =
        this.loanAccountDetails?.penaltySettings?.penaltyRate;
      this.loanForm.periodicPayment = 0; // this.loanAccountDetails;
      this.loanForm.arrearsTolerancePeriod =
        this.loanAccountDetails.arrearsTolerancePeriod;
      this.loanForm.productTypeKey = this.loanAccountDetails.productTypeKey;
    },
    clearLoanFormValidation() {
      this.loanFormErr = {
        id: false,
        loanAmount: false,
        repaymentPeriodCount: false,
        repaymentPeriodUnit: false,
        repaymentInstallments: false,
        gracePeriod: false,
        interestRate: false,
        interestSpread: false,
        principalRepaymentInterval: false,
        penaltyRate: false,
        periodicPayment: false,
        arrearsTolerancePeriod: false,
      };
    },

    // Status Approve reject rework
    preOpenStatusForm(status) {
      this.clearStatusForm();
      this.statusForm.taskKey = this.taskKey;
      if (status == "Rework") {
        this.statusForm.status = "ReworkQueue";
        this.statusForm.type = status;
      } else {
        this.statusForm.status = status;
        this.statusForm.type = status;
      }
    },
    saveStatusForm: async function (e) {
      e.preventDefault();
      if (this.validateStatusForm()) {
        if (this.statusForm.status == "Pending Acceptance") {
          try {
            this.isSaving = true;
            const payload = {
              loanAccountKey: this.loanAccountId,
              isSendOffer: true,
              isApproveOverride: false,
            };
            const res = await underwritingService.offerAcceptance(payload);
            if (res.status == 200) {
              this.alertSuccess(res.data.message);

              // this.statusForm.status = 'Approve';
              // this.statusForm.type = 'Approve';

              // try {
              //   this.isSaving = true;
              //   const res = await underwritingService.changeTaskStatus(
              //     this.statusForm
              //   );
              //   if (res.status == 200) {
              //     this.alertSuccess(res.data.message);
              //     this.getLoanAccountDetails();
              //     this.getUnderwritingTask();
              //   } else {
              //     this.alertError(res.data.message);
              //   }
              //   // document.getElementById("statusFormCloseButton").click();
              //   // this.clearStatusForm();
              //   this.isSaving = false;
              // } catch (e) {
              //   this.isSaving = false;
              //   const error = e.response.data.message || e.response.data.data;
              //   this.alertError(error);
              // }
              this.getLoanAccountDetails();
              this.getUnderwritingTask();
            } else {
              this.alertError(res.data.message);
            }
            document.getElementById("statusFormCloseButton").click();
            this.clearStatusForm();
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
        } else if (this.statusForm.status == "Approve override") {
          try {
            this.isSaving = true;
            const payload = {
              loanAccountKey: this.loanAccountId,
              isSendOffer: false,
              isApproveOverride: true,
            };
            const res = await underwritingService.offerAcceptance(payload);
            if (res.status == 200) {
              this.alertSuccess(res.data.message);

              // this.statusForm.status = 'Approve';
              // this.statusForm.type = 'Approve';

              // try {
              //   this.isSaving = true;
              //   const res = await underwritingService.changeTaskStatus(
              //     this.statusForm
              //   );
              //   if (res.status == 200) {
              //     this.alertSuccess(res.data.message);
              //     this.getLoanAccountDetails();
              //     this.getUnderwritingTask();
              //   } else {
              //     this.alertError(res.data.message);
              //   }
              //   // document.getElementById("statusFormCloseButton").click();
              //   // this.clearStatusForm();
              //   this.isSaving = false;
              // } catch (e) {
              //   this.isSaving = false;
              //   const error = e.response.data.message || e.response.data.data;
              //   this.alertError(error);
              // }
              this.getLoanAccountDetails();
              this.getUnderwritingTask();
            } else {
              this.alertError(res.data.message);
            }
            document.getElementById("statusFormCloseButton").click();
            this.clearStatusForm();
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
        } else {
          try {
            this.isSaving = true;
            if (this.statusForm.status !== "Approve") {
              this.statusForm.approvalComment =
                this.statusForm.selectedReason.name +
                "-> " +
                this.statusForm.approvalComment;
            }
            const res = await underwritingService.changeTaskStatus(
              this.statusForm
            );
            if (res.status == 200) {
              this.alertSuccess(res.data.message);
              // if (this.statusForm.status === "ReworkQueue") {
              document.getElementById("statusFormCloseButton").click();
              return this.$router.push({ path: "/underwriting-dashboard" });
              // }
              // this.getLoanAccountDetails();
              // this.getUnderwritingTask();
            } else {
              this.alertError(res.data.message);
            }
            document.getElementById("statusFormCloseButton").click();
            this.clearStatusForm();
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    clearStatusForm() {
      this.statusForm = {
        taskKey: null,
        status: null,
        approvalComment: "",
        accessUserKey: null,
        reasonObjs: [],
        reasons: [],
        selectedReason: null,
        underwriters: [],
      };
    },
    validateStatusForm() {
      this.clearStatusFormValidation();
      if (this.statusForm.status) {
        return true;
      }

      for (const key in this.statusFormErr) {
        if (Object.hasOwnProperty.call(this.statusFormErr, key)) {
          if (
            this.statusForm[key] == null ||
            this.statusForm[key] == undefined ||
            this.statusForm[key].trim() == ""
          ) {
            this.statusFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearStatusFormValidation() {
      this.statusFormErr = {
        status: false,
      };
    },
    addReasonToStatusForm() {
      const reason = this.statusForm.selectedReason;
      if (!this.statusForm.reasons.includes(reason.reasonKey)) {
        this.statusForm.reasons.push(reason.reasonKey);
        this.statusForm.reasonObjs.push(reason);
      }
    },
    removeReasonFromStatusForm(reasonKey) {
      let ind = this.statusForm.reasons.findIndex((x) => x == reasonKey);
      this.statusForm.reasons.splice(ind, 1);

      let ind2 = this.statusForm.reasonObjs.findIndex(
        (x) => x.reasonKey == reasonKey
      );
      this.statusForm.reasonObjs.splice(ind2, 1);
    },

    async regenerateRepaymentSchedule() {
      this.isRegenerating = true;
      this.regeneratedSchedule = null;
      try {
        let model = {
          loanAccountKey: this.loanAccountId,
          loanProductKey:
            this.loanAccountDetails?.adminLoanProduct?.loan_product_key,
          loanAmount: this.loanAccountDetails?.loanAmount,
          repaymentInstallments: 0,
          interestRate: this.loanAccountDetails?.interestSettings?.interestRate,
          disbursmentDate:
            this.loanAccountDetails?.disbursementDetails?.disbursementDate,
          firstRepaymentDate: this.firstRepaymentDate,
          interestCalculationMethodEnum:
            this.loanAccountDetails?.interestSettings
              ?.interestCalculationMethod || 0,
          daysInYearsEnum: 0,
          repaymentFrequency: 0,
          howIsInterestRateChargedEnum: 0,
          repaymentInterestCalculationEnum: 0,
          roundingRepaymentSchedules: 0,
          roundToNearestWholeNumber: 0,
          collectPrincipalEvery: 0,
          gracePeriodValue: this.loanAccountDetails?.adminLoanProduct
            ?.default_grace_period
            ? this.loanAccountDetails?.adminLoanProduct?.default_grace_period
            : 0,
          gracePeriod: Number(
            this.loanAccountDetails?.adminLoanProduct?.grace_period_type
          ),
          loanAccountProductFeesList: this.newLoanFees.map((fee) => fee.feeKey),
          repaymentAreMadeEveryEnum:
            this.loanAccountDetails?.adminLoanProduct
              ?.repayment_rescheduling_method,
        };
        const res = await otherService.regenerateRepaymentSchedule(model);
        this.regeneratedSchedule = res.data.data;
        // let a = this.regeneratedSchedule.repayments[0];
        // for (let i = 1; i < 100; i++) {
        //   this.regeneratedSchedule.repayments.push(a);
        // }
        this.isRegenerating = false;
      } catch (e) {
        this.isRegenerating = false;
        const error = e.response.data.message || e.response.data.data;
        this.alertError(error);
      }
    },

    getLoansNotRequired(value) {
      const { loanProductFees } = this.account_details;
      if (value) {
        const filArray = this.loanFeesNotRequired.filter(
          (fee) => fee.isRequired !== true && !value.includes(fee.feeKey)
        );
        //this.filteredLoanFee = filArray;
        this.newLoanFees = filArray.map((newLoan) => ({
          ...newLoan,
          newFees: [newLoan],
        }));
        this.newFees = filArray.map((loan) => loan.feeKey);
      } else {
        //this.filteredLoanFee = loanProductFees.loanProductFees.filter((fee) => fee.isRequired !== true);
        this.newLoanFees = loanProductFees.loanProductFees.filter(
          (fee) => fee.isRequired !== true
        );
      }
    },

    // Paging
    changePage(type, pageNum) {
      switch (type) {
        case "schedule":
          this.scheduleFetchModel.pageIndex = pageNum;
          this.getLoanAccountDetailsSchedule("schedule");
          break;
        case "amountExpected":
          this.amountExpectedFetchModel.pageIndex = pageNum;
          this.getLoanAccountDetailsSchedule("amountExpected");
          break;
        case "amountPaid":
          this.amountPaidFetchModel.pageIndex = pageNum;
          this.getLoanAccountDetailsSchedule("amountPaid");
          break;
        case "amountDue":
          this.amountDueFetchModel.pageIndex = pageNum;
          this.getLoanAccountDetailsSchedule("amountDue");
          break;
        case "document":
          this.documentFetchModel.pageIndex = pageNum;
          this.getLoanDocuments();
          break;
        default:
          break;
      }
    },
    stepPage(type, direction) {
      switch (type) {
        case "schedule":
          if (direction == "next") {
            this.scheduleFetchModel.pageIndex =
              this.schedulePaging.pageIndex + 1;
          }
          if (direction == "previous") {
            this.scheduleFetchModel.pageIndex =
              this.schedulePaging.pageIndex - 1;
          }
          this.getLoanAccountDetailsSchedule("schedule");
          break;
        case "amountExpected":
          if (direction == "next") {
            this.amountExpectedFetchModel.pageIndex =
              this.amountExpectedPaging.pageIndex + 1;
          }
          if (direction == "previous") {
            this.amountExpectedFetchModel.pageIndex =
              this.amountExpectedPaging.pageIndex - 1;
          }
          this.getLoanAccountDetailsSchedule("amountExpected");
          break;
        case "amountPaid":
          if (direction == "next") {
            this.amountPaidFetchModel.pageIndex =
              this.amountPaidPaging.pageIndex + 1;
          }
          if (direction == "previous") {
            this.amountPaidFetchModel.pageIndex =
              this.amountPaidPaging.pageIndex - 1;
          }
          this.getLoanAccountDetailsSchedule("amountPaid");
          break;
        case "amountDue":
          if (direction == "next") {
            this.amountDueFetchModel.pageIndex =
              this.amountDuePaging.pageIndex + 1;
          }
          if (direction == "previous") {
            this.amountDueFetchModel.pageIndex =
              this.amountDuePaging.pageIndex - 1;
          }
          this.getLoanAccountDetailsSchedule("amountDue");
          break;
        case "document":
          if (direction == "next") {
            this.documentFetchModel.pageIndex =
              this.documentPaging.pageIndex + 1;
          }
          if (direction == "previous") {
            this.documentFetchModel.pageIndex =
              this.documentPaging.pageIndex - 1;
          }
          this.getLoanDocuments();
          break;
        default:
          break;
      }
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    // alerts
    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      if (isNaN(value)) {
        return "_";
      }
      return "₦" + val;
    },
    show(status) {
      if (status === "Paid" && this.paidRepayment) {
        return true;
      }
      if (status === "Paid" && !this.paidRepayment) {
        return false;
      } else {
        return true;
      }
    },

    totalExpectedAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.expectedPrincipal;
      }, 0);
      return sum;
    },
    totalExpectedInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestExpected;
      }, 0);
      return sum;
    },
    totalExpectedFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feeExpected;
      }, 0);
      return sum;
    },
    totalExpectedPen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyExpected;
      }, 0);
      return sum;
    },
    totalExpectedSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalExpected;
      }, 0);
      return sum;
    },

    //Amount paid
    totalPaidAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.principalPaid;
      }, 0);
      return sum;
    },
    totalPaidInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestPaid;
      }, 0);
      return sum;
    },
    totalPaidFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feesPaid;
      }, 0);
      return sum;
    },
    totalPaidPen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyPaid;
      }, 0);
      return sum;
    },
    totalPaidSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalPaid;
      }, 0);
      return sum;
    },

    //Amount Due
    //Amount paid
    totalDueAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.principalDue;
      }, 0);
      return sum;
    },
    totalDueInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestDue;
      }, 0);
      return sum;
    },
    totalDueFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feesDue;
      }, 0);
      return sum;
    },
    totalDuePen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyDue;
      }, 0);
      return sum;
    },
    totalDueSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalDue;
      }, 0);
      return sum;
    },

    repaymentAreMadeEvery(repaymentPeriodUnit) {
      const unit = this.enums.repaymentAreMadeEvery.find(
        (unit) => unit.id == repaymentPeriodUnit
      );
      if (unit) {
        return unit.name.slice(0, -1);
      } else {
        return "";
      }
    },
    howIsTheInterestRateCharged(interestRate) {
      const unit = this.enums.howIsTheInterestRateCharged.find(
        (unit) => unit.id == interestRate
      );
      if (unit) {
        return unit.name.slice(0, -1);
      } else {
        return "";
      }
    },
    interestCalculationMethod(method) {
      const unit = this.enums.interestCalculationMethod.find(
        (unit) => unit.id == method
      );
      if (unit) {
        return unit.name;
      } else {
        return "_";
      }
    },
    accruedInterestPostingFrequency(method) {
      const unit = this.enums.accruedInterestPostingFrequency.find(
        (unit) => unit.id == method
      );
      if (unit) {
        return unit.name;
      } else {
        return "";
      }
    },
    totalPaid(d) {
      let t = 0;
      t += d.feesPaid;
      t += d.interestPaid;
      t += d.penaltyPaid;
      t += d.principalPaid;
      t += d.interestFromArrearsPaid;
      return t;
    },
    getAllUsers() {
      AccessApiService.get(
        "ProleanUser/GetUsers/pageNumber/1/pageSize/10/branchKey/all/userStatus/false"
      ).then((res) => {
        this.allUsers = res.data.data;
      });
    },
    getCurrentUsers() {
      AccessApiService.post("ProleanUser/getuserdetails").then((res) => {
        this.currentUser = res.data.data;
      });
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.auth?.user?.access_Token,
      user: (state) => state.auth.user,
      newEnums: (state) => state.loan.enums,
    }),
    canSendOfferLetter() {
      return this.task.canSendOfferLetter;
    },
    userCanRework() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanAddReworkReason;
    },
    userCanReject() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanAddRejectReason;
    },
    userCanApprove() {
      const underwriting = this.user.scopes.find(
        (item) => item.scopeName === Role.Underwrting
      );
      const { permissionsList } = underwriting;
      return permissionsList.CanApproveLoan;
    },
    userCanUploadAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanCreateDocuments;
    },
    userCanEditAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanEditDocuments;
    },
    userCanDeleteAttachment() {
      const document = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = document;
      return permissionsList.CanDeleteDocuments;
    },
    userCanEditLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanEditLoanAccount;
    },
    userCanViewLoanAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = account;
      return permissionsList.CanViewLoanAccount;
    },
    userCanCreateLoanAccount() {
      const loan = this.user.scopes.find(
        (item) => item.scopeName === Role.LoanAccounts
      );
      const { permissionsList } = loan;
      return permissionsList.CanCreateLoanAccount;
    },
    isApprovedByUser() {
      if (this.task) {
        return (
          this.task.role.toLowerCase() == "primaryapproval" &&
          this.task.approverStatus.toLowerCase() == "approve"
        );
      }
      return false;
    },
    userCanCreateCustomField() {
      const deposit = this.user.scopes.find(
        (item) => item.scopeName === Role.CustomField
      );
      const { permissionsList } = deposit;
      return permissionsList.CanCreateCustomField;
    },
    isSecondApproval() {
      if (this.task) {
        return (
          this.task.firstApprovalReviewStatus.toLowerCase() == "approve" ||
          this.task.secondApprovalReviewStatus.toLowerCase() == "approve"
        );
      }
      return false;
    },
    productHasSecurity() {
      const collateralEnabled = this.loanAccountDetails
        ? this.loanAccountDetails.totalSecurityAmount <= 0
        : 0;
      const guarantorsEnabled = this.loanAccountDetails
        ? this.loanAccountDetails.percentagePrincipalGuaranteed <= 0
        : 0;
      return collateralEnabled || guarantorsEnabled;
    },
    enums() {
      return this.$store.state.loan.enums;
    },
    getFirstRepayment() {
      return this.schedule ? this.schedule[0]?.due : new Date();
    },
    loanInterestType() {
      try {
        const hh = this.enums.repaymentAreMadeEvery.find(
          (h) =>
            h.id ==
            this.loanAccountDetails?.adminLoanProduct?.repayment_schedule_method
        );
        return hh.name;
      } catch (e) {
        return "";
      }
    },
    interestRateDisplay() {
      const unit = this.enums.howIsTheInterestRateCharged.find(
        (unit) =>
          unit.id == this.loanAccountDetails?.adminLoanProduct?.interest_type
      );
      if (unit) {
        return unit.name;
      } else {
        return "_";
      }
    },
    prepaymentAcceptance() {
      try {
        const hh = this.enums.prepaymentAcceptance.find(
          (h) =>
            h.id ==
            this.loanAccountDetails.adminLoanProduct.prepayment_acceptance
        );
        return hh.name;
      } catch (e) {
        return "";
      }
    },
    approvalHistory() {
      return this.task.approvalHistories[0];
    },
    loanIsFirstAprroved() {
      return this.task ? this.task.firstApprovalReviewStatus == "Approve" : "";
    },
    loanIsSecondAprroved() {
      return this.task ? this.task.secondApprovalReviewStatus == "Approve" : "";
    },
    secondApproval() {
      if (this.task.role == "primaryapproval") return true;
      if (this.task.secondApprovalReviewStatus == "Approve") return true;
      return false;
    },
    hideButtons() {
      return (
        this.loanRejected &&
        this.loanApprove &&
        (this.task !== null || this.task !== "")
      );
    },
    isCurrentUserFirstApprover() {
      try {
        return (
          this.task.approvalHistories[0].currentApprovalLevel !== ""
          // this.currentUser.userKey == this.task.firstApprovalUserKey &&
          // this.task.role == "PrimaryApproval"
        );
      } catch (e) {
        return "";
      }
    },
    previousApprovalExists() {
      try {
        return (
          this.task.approvalHistories[0].previousApprovalLevelUserKey !== ""
        );
      } catch (e) {
        return "";
      }
    },
    nextApprovalExists() {
      try {
        return this.task.approvalHistories[0].nextApprovalLevelUserKey !== "";
      } catch (e) {
        return "";
      }
    },
    isNotLevelOneApproval() {
      try {
        return (
          this.approvalHistory.currentApprovalLevel.toLowerCase() !==
          "leveloneapproval"
        );
      } catch (e) {
        return "";
      }
    },
    userCanSendOfferLetter() {
      try {
        return this.approvalHistory.canSendOfferLetter;
      } catch (e) {
        return "";
      }
    },
    isCurrentUserSecondApprover() {
      try {
        return (
          this.currentUser.userKey == this.task.secondApprovalUserKey &&
          this.task.role == "SecondaryApproval"
        );
      } catch (e) {
        return "";
      }
    },
    decodedToken() {
      return VueJwtDecode.decode(this.token);
    },
    isLoggedInUser() {
      return (
        this.task.firstApprovalUserKey == this.getCurrentUser ||
        this.task.secondApprovalUserKey == this.getCurrentUser
      );
    },
    AllowAutoDisbursement() {
      const allow_auto_disbursement =
        this.loanAccountDetails?.adminLoanProduct?.allow_auto_disbursement;
      if (allow_auto_disbursement === null) return false;
      return allow_auto_disbursement;
    },
    AllowUnderwriterOveride() {
      const allow_underwriter_overide =
        this.loanAccountDetails?.adminLoanProduct?.allow_underwriter_overide;
      if (allow_underwriter_overide === null) return false;
      return allow_underwriter_overide;
    },
    RequireOfferAcceptance() {
      const require_offer_acceptance =
        this.loanAccountDetails?.adminLoanProduct?.require_offer_acceptance;
      if (require_offer_acceptance === null) return false;
      return require_offer_acceptance;
    },
    canOveride() {
      return this.AllowUnderwriterOveride && this.RequireOfferAcceptance;
    },
    loanIsTwoLevelApproval() {
      return this.task.secondApprovalUserKey != null;
    },
    isOVerRide() {
      return this.loanAccountDetails.overrideStatus.toLowerCase() === "yes";
    },
    uniqueQueue() {
      const map = new Map(
        this.task.approvals.map((obj) => [obj.approvalLevel, obj])
      );
      return [...map.values()];
    },
    // isCurrentUserFirstApprover
  },
  created() {
    this.loanAccountId = this.$route.params.id;
    this.taskKey = this.$route.params.taskKey;
    this.scheduleFetchModel.loanAccountId = this.loanAccountId;
    this.amountExpectedFetchModel.loanAccountId = this.loanAccountId;
    this.amountPaidFetchModel.loanAccountId = this.loanAccountId;
    this.amountDueFetchModel.loanAccountId = this.loanAccountId;
    this.commentForm.parentKey = this.loanAccountId;
    this.documentForm.documentHolderKey = this.loanAccountId;
    this.documentFetchModel.holderKey = this.$route.params.taskKey;
    this.documentForm.documentLocation = `LoanAccount${this.loanAccountId}`;

    this.getLoanAccountDetails(true);
    // this.getLoanAccountDetailsSchedule();
    // this.getLoanAccountDetailsTransactions();
    // this.getLoanAccountDetailsActivities();
    // this.getLoanAccountDetailsAttachments();
    // this.getLoanAccountDetailsComments();
    // this.getLoanComments();
    this.getReasons();
    // this.getLoanDocuments();
    this.getUnderwritingTask();
    // this.getAllUsers();
    // this.getCurrentUsers();
    // this.getCustomFieldSets();
    this.getAccountCustomFieldSets();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    formatCurrencyPipe: function (number) {
      if (number == null || number == undefined || number == "") return "0.00";
      const n = Number(number);
      return n.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    formatEnumToProper: function (str) {
      let words = str.replace("_", " ");
      let separateWord = words.toLowerCase().split(" ");
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1).toLowerCase();
      }
      return separateWord.join(" ");
    },
    fromNow: function (date) {
      return moment(date).fromNow();
    },
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    kb(val) {
      const sizes = ["bytes", "KB", "MB", "GB"];
      if (val == 0) return "";
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i == 0) return `${val} ${sizes[i]}`;
      return `${(val / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
  },
  mounted() {
    this.fRDate = this.schedule ? this.schedule[0]?.due : new Date();
  },
});
</script>
<style lang="scss">
.w-150 {
  width: 150%;
}
.page .details-card--red {
  background-color: #eb1710;
}
.align-center {
  align-items: center;
}
.table__amount {
  &__right {
    border-right: 2px solid rgba(224, 224, 224, 0.8);
    padding-right: 10px !important;

    & span {
      padding-right: 10px !important;
    }
  }

  &__left {
    border-left: 2px solid rgba(224, 224, 224, 0.8);
    padding-left: 10px !important;

    & span {
      padding-left: 10px !important;
    }
  }
}
.customtable {
  width: 100%;
}
.customtable .table__amount__left {
  padding-left: 10px !important;
}
#regeneratedScheduleModal .modal__dialog {
  width: 85rem;
}
#regeneratedScheduleModal .modal__content {
  padding: 4.5rem 2.5rem;
}
.fitrem {
  min-width: 15rem;
}
.details-card--black .card_title {
  color: #000 !important;
}
.details-card--black .card_subtitle {
  color: #000 !important;
}
</style>
